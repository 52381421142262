import {Injectable} from '@angular/core';
import {IScope} from "./scope.interface";
import {distinctUntilChanged, first, Observable, switchMap, tap} from "rxjs";
import {UserScope} from "./user.scope";
import {map} from "rxjs/operators";
import {AuthTokenLoader} from "./authTokenLoader";
import {permanentCache} from "@juulsgaard/rxjs-tools";
import {AuthClient} from "../clients/auth.client";
import {AuthService} from "@lib/services/auth.service";

@Injectable()
export class AdminScope implements IScope {

  reset$: Observable<void>;

  authToken$: Observable<string>;

  isSuperAdmin$: Observable<boolean>;
  get isSuperAdmin() {return this.userScope.isSuperAdmin}

  constructor(private userScope: UserScope, private authService: AuthService, private client: AuthClient) {
    this.reset$ = userScope.reset$;
    this.isSuperAdmin$ = userScope.isSuperAdmin$;

    const token$ = this.userScope.userId$.pipe(
      distinctUntilChanged(),
      map(() => new AuthTokenLoader(() => this.client.getAdminToken())),
      permanentCache()
    );

    // Evaluate token on request
    this.authToken$ = token$.pipe(
      first(),
      switchMap(x => x.getToken$()),
      tap({error: (e: Error) => this.authService.logOutIfCritical(e)})
    );
  }

}

import {StoreService} from "@juulsgaard/store-service";
import {SimpleObject} from "@juulsgaard/ts-tools";
import {inject, Injectable, OnDestroy} from "@angular/core";
import {StoreConfigService} from "@lib/services/store-config.service";
import {IScope} from "@lib/scopes/scope.interface";
import {Subscription} from "rxjs";

@Injectable()
export abstract class ScopedStore<T extends SimpleObject> extends StoreService<T> implements OnDestroy {

  sub: Subscription;

  protected constructor(initialState: T, scope: IScope) {
    super(initialState, inject(StoreConfigService));

    this.sub = scope.reset$.subscribe(() => this.reset());
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}

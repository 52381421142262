import {Injectable} from "@angular/core";
import {ScopedStore} from "@lib/helpers/stores/scoped-store";
import {AdminScope} from "@lib/scopes/admin.scope";
import {Reducers} from "@lib/helpers/stores/reducers";
import {CompositionGroupStoreState} from "@admin/stores/compositions/composition-group.models";
import {CompositionGroupClient} from "@admin/stores/compositions/composition-group.client";
import {WhereItem} from "@juulsgaard/store-service";
import {map} from "rxjs/operators";
import {arrToMap} from "@juulsgaard/ts-tools";

@Injectable()
export class CompositionGroupStore extends ScopedStore<CompositionGroupStoreState> {

  groups$ = this.selectNotNull(x => x.groups);
  groupLookup$ = this.selector(
    this.groups$.pipe(map(list => arrToMap(list, x => x.id)))
  );

  constructor(scope: AdminScope, private client: CompositionGroupClient) {
    super({}, scope);
  }

  loadGroups = this.command(this.client)
    .withAction(c => c.loadGroups)
    .isInitial()
    .withReducer(groups => ({groups}));

  loadGroup = this.command(this.client)
    .withAction(c => c.loadGroup)
    .cancelConcurrent(x => x)
    .targetList('groups')
    .withReducer(Reducers.updateById());

  //<editor-fold desc="Groups">

  createGroup = this.command(this.client)
    .withAction(c => c.createGroup)
    .withSuccessMessage('Created Composition Group')
    .targetList('groups')
    .withReducer(Reducers.addition());

  updateGroup = this.command(this.client)
    .withAction(c => c.updateGroup)
    .withSuccessMessage('Updated Composition Group')
    .targetList('groups')
    .withReducer(Reducers.updateById());

  moveGroup = this.command(this.client)
    .withDeferredAction(c => c.moveGroup)
    .withSuccessMessage('')
    .targetList('groups')
    .withReducer(Reducers.moveById());

  deleteGroup = this.command(this.client)
    .withDeferredAction(c => c.deleteGroup)
    .withSuccessMessage('Deleted Composition Group')
    .targetList('groups')
    .withReducer(Reducers.deleteWithIndexById());

  deleteAlias = this.command(this.client)
    .withDeferredAction(c => c.deleteAlias)
    .withSuccessMessage('Deleted Alias')
    .targetList('groups')
    .targetItem(WhereItem.IdMatchesPayload(x => x.parentId))
    .targetList('aliases')
    .modify(x => x.aliasId)
    .withReducer(Reducers.deleteById());

  //</editor-fold>
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  AdminUserAdminModel, AdminUserCreateModel, AdminUserListModel, AdminUserUpdateModel
} from "@admin/stores/admins/admin-user.models";
import {tap} from "rxjs";
import {UserPasswordUpdateModel} from "@lib/models/generic.models";

@Injectable({providedIn: 'root'})
export class AdminUserClient {
  constructor(private httpClient: HttpClient) {
  }

  loadAdmins() {
    return this.httpClient.get<AdminUserListModel[]>(`admin://admins`);
  }

  loadAdmin(userId: string) {
    return this.httpClient.get<AdminUserAdminModel>(`admin://admin/${userId}`)
      .pipe(tap(x => x.loaded = true));
  }

  createAdmin(data: AdminUserCreateModel) {
    return this.httpClient.post<AdminUserListModel>(`admin://admins`, data);
  }

  updateAdmin({id, ...data}: AdminUserUpdateModel) {
    return this.httpClient.put<AdminUserAdminModel>(`admin://admin/${id}`, data);
  }

  requestPassword(userId: string) {
    return this.httpClient.patch<void>(`admin://admin/${userId}/new-password`, {});
  }

  updatePassword({userId, ...data}: UserPasswordUpdateModel) {
    return this.httpClient.patch<void>(`admin://admin/${userId}/password`, data);
  }

  resetLoginAttempts(userId: string) {
    return this.httpClient.patch<void>(`admin://admin/${userId}/login-attempts`, {});
  }

  removeAdmin(userId: string) {
    return this.httpClient.delete<void>(`admin://admin/${userId}`);
  }
}

import {Injectable} from "@angular/core";
import {DialogService} from "@juulsgaard/ngx-material";
import {FormConfirmService} from "@juulsgaard/ngx-forms-core";

@Injectable()
export class DialogFormConfirmService extends FormConfirmService {

  constructor(private dialogs: DialogService) {
    super();
  }

  confirmDelete(title: string, text: string, btnText?: string): Promise<boolean> {
    return this.dialogs.delete(title, text, {deleteText: btnText});
  }

  confirmSubmit(title: string, text: string, btnText?: string): Promise<boolean> {
    return this.dialogs.confirm(title, text, {confirmText: btnText});
  }

}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Tenant} from "@tenant/stores/scope/tenant-scope.models";

@Injectable({providedIn: 'root'})
export class TenantScopeClient {

  constructor(private httpClient: HttpClient) {
  }

  loadTenant() {
    return this.httpClient.get<Tenant>(`tenant://tenant`);
  }
}

import {HttpStatusCode} from "@angular/common/http";

export class ApiException extends Error {

    constructor(message: string, public statusCode: HttpStatusCode, public correlationId?: string) {
        super(message);
    }


}

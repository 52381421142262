import {inject, Injectable} from '@angular/core';
import {ScopedStore} from "@lib/helpers/stores/scoped-store";
import {TemplateState} from "@tenant/stores/templates/template.models";
import {TemplateClient} from "@tenant/stores/templates/template.client";
import {TenantScope} from "@lib/scopes/tenant.scope";
import {Reducers} from "@lib/helpers/stores/reducers";

@Injectable()
export class TemplateStore extends ScopedStore<TemplateState> {

  printTemplates$ = this.selectNotNull(x => x.printTemplates);

  constructor(private client: TemplateClient) {
    super({}, inject(TenantScope));
  }

  loadPrintTemplates = this.command(this.client)
    .withAction(c => c.loadPrintTemplates)
    .isInitial()
    .withReducer(printTemplates => ({printTemplates}));

  createTemplate = this.command(this.client)
    .withAction(c => c.createTemplate)
    .withSuccessMessage('Created template')
    .targetList('printTemplates')
    .withReducer(Reducers.addition());

  updateTemplate = this.command(this.client)
    .withAction(c => c.updateTemplate)
    .withSuccessMessage('Updated Template')
    .targetList('printTemplates')
    .withReducer(Reducers.updateById());

  replaceFile = this.command(this.client)
    .withAction(c => c.replaceFile)
    .withSuccessMessage('Updates Image')
    .targetList('printTemplates')
    .withReducer(Reducers.updateById());

  setDefault = this.command(this.client)
    .withDeferredAction(c => c.setDefault)
    .withSuccessMessage('Set template as default')
    .targetList('printTemplates')
    .withReducer((id, list) => list.map(x => ({...x, default: x.id === id})));

  deleteTemplate = this.command(this.client)
    .withDeferredAction(c => c.deleteTemplate)
    .withSuccessMessage('Deleted Template')
    .targetList('printTemplates')
    .withReducer(Reducers.deleteById());
}

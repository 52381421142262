import {Injectable} from "@angular/core";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {catchError, Observable, throwError} from "rxjs";
import {ApiException} from "../exceptions/api.exception";

@Injectable({providedIn: 'root'})
export class ExceptionInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      catchError(e => throwError(() => ExceptionInterceptor.parseError(e)))
    );
  }

  private static parseError(error: any) {
    if (error instanceof HttpErrorResponse) {
      return new ApiException(
        error.error?.error ?? 'Something went wrong',
        error.status,
        error.headers.get('x-correlation-id') ?? undefined
      );
    }

    return new ApiException('Something went wrong', 500);
  }
}

import {Provider} from "@angular/core";
import {ColorStore} from "@tenant/stores/colors/color.store";
import {FacilityStore} from "@tenant/stores/facilities/facility.store";
import {TagStore} from "@tenant/stores/tags/tag.store";
import {TenantUserStore} from "@tenant/stores/users/tenant-user.store";
import {TenantScopeStore} from "@tenant/stores/scope/tenant-scope.store";
import {ClaimStore} from "@tenant/stores/claims/claim.store";
import {ReviewStore} from "@tenant/stores/reviews/review.store";
import {ImportStore} from "@tenant/stores/imports/import.store";
import {TenantSettingsStore} from "@tenant/stores/settings/tenant-settings.store";
import {TemplateStore} from "@tenant/stores/templates/template.store";
import {TenantDashboardStore} from "@tenant/stores/dashboard/tenant-dashboard.store";

export const tenantStores: Provider[] = [
  ColorStore,
  FacilityStore,
  TagStore,
  TenantUserStore,
  TenantScopeStore,
  ClaimStore,
  ReviewStore,
  ImportStore,
  TenantSettingsStore,
  TemplateStore,
  TenantDashboardStore
];

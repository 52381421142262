import {Provider} from "@angular/core";
import {FacilityUserStore} from "@facility/stores/users/facility-user.store";
import {FacilityScopeStore} from "@facility/stores/scope/facility-scope.store";
import {ProductStore} from "@facility/stores/products/product.store";
import {FacilityDashboardStore} from "@facility/stores/dashboard/facility-dashboard.store";

export const facilityStores: Provider[] = [
  FacilityUserStore,
  FacilityScopeStore,
  ProductStore,
  FacilityDashboardStore
];

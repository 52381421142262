import {Injectable} from '@angular/core';
import {ScopedStore} from "@lib/helpers/stores/scoped-store";
import {ColorStoreState} from "@tenant/stores/colors/color.models";
import {TenantScope} from "@lib/scopes/tenant.scope";
import {ColorClient} from "@tenant/stores/colors/color.client";
import {Reducers} from "@lib/helpers/stores/reducers";

@Injectable()
export class ColorStore extends ScopedStore<ColorStoreState> {

  colors$ = this.selectNotNull(x => x.colors);

  constructor(scope: TenantScope, private client: ColorClient) {
    super({}, scope);
  }

  loadColors = this.command(this.client)
    .withAction(c => c.loadColors)
    .isInitial()
    .withReducer(colors => ({colors}));


  createColor = this.command(this.client)
    .withAction(c => c.createColor)
    .withSuccessMessage('Created Color')
    .targetList('colors')
    .withReducer(Reducers.addition());

  updateColor = this.command(this.client)
    .withAction(c => c.updateColor)
    .withSuccessMessage('Updated Color')
    .targetList('colors')
    .withReducer(Reducers.updateById());

  archiveColor = this.command(this.client)
    .withDeferredAction(c => c.archiveColor)
    .withSuccessMessage('Archived Color')
    .targetList('colors')
    .withReducer(Reducers.deleteById());
}

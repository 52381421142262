import {Observable, of, tap} from "rxjs";
import {TokenResponse} from "../clients/auth.client";
import {map} from "rxjs/operators";
import {parseJwt} from "@juulsgaard/ts-tools";
import {permanentCache} from "@juulsgaard/rxjs-tools";

export class AuthTokenLoader {

  private token?: string
  private token$?: Observable<string>;
  private expiry = 0

  constructor(private getToken: () => Observable<TokenResponse>) {
  }

  getToken$(): Observable<string> {
    if (this.token$) return this.token$;

    if (!this.token) {
      this.token$ = this.request();
      return this.token$;
    }

    if ((new Date().getTime() / 1000) > this.expiry) {
      this.token$ = this.request();
      return this.token$;
    }

    return of(this.token);
  }

  private request(): Observable<string> {
    return this.getToken().pipe(map(x => x.token), tap(token => {
      const data = parseJwt<TokenData>(token);
      this.expiry = data.exp - 120;
      this.token = token;
      this.token$ = undefined;
    }), permanentCache());
  }
}

interface TokenData {
  exp: number;
}

import {Injectable} from '@angular/core';
import {ScopedStore} from "@lib/helpers/stores/scoped-store";
import {MaterialStoreState} from "@admin/stores/materials/material.models";
import {AdminScope} from "@lib/scopes/admin.scope";
import {MaterialClient} from "@admin/stores/materials/material.client";
import {Reducers} from "@lib/helpers/stores/reducers";
import {WhereItem} from "@juulsgaard/store-service";
import {map} from "rxjs/operators";
import {arrToMap} from "@juulsgaard/ts-tools";

@Injectable()
export class MaterialStore extends ScopedStore<MaterialStoreState> {

  materials$ = this.selectNotNull(x => x.materials);
  materialLookup$ = this.selector(
    this.materials$.pipe(map(list => arrToMap(list, x => x.id)))
  );

  constructor(scope: AdminScope, private client: MaterialClient) {
    super({}, scope);
  }

  loadMaterials = this.command(this.client)
    .withAction(c => c.loadAdminMaterials)
    .isInitial()
    .withReducer(materials => ({materials}));

  loadMaterial = this.command(this.client)
    .withAction(c => c.loadAdminMaterial)
    .cancelConcurrent(x => x)
    .targetList('materials')
    .withReducer(Reducers.updateById());

  //<editor-fold desc="Materials ">

  createMaterial = this.command(this.client)
    .withAction(c => c.createMaterial)
    .withSuccessMessage('Created Material')
    .targetList('materials')
    .withReducer(Reducers.addition());

  updateMaterial = this.command(this.client)
    .withAction(c => c.updateMaterial)
    .withSuccessMessage('Material updated')
    .targetList('materials')
    .withReducer(Reducers.updateById());

  archiveMaterial = this.command(this.client)
    .withDeferredAction(c => c.archiveMaterial)
    .withSuccessMessage('Archived Material')
    .targetList('materials')
    .withReducer(Reducers.deleteById());

  deleteAlias = this.command(this.client)
    .withDeferredAction(c => c.deleteAlias)
    .withSuccessMessage('Deleted Alias')
    .targetList('materials')
    .targetItem(WhereItem.IdMatchesPayload(x => x.parentId))
    .targetList('aliases')
    .modify(x => x.aliasId)
    .withReducer(Reducers.deleteById());

  loadVariant = this.command(this.client)
    .withAction(c => c.loadAdminMaterialVariant)
    .cancelConcurrent(x => x.variantId)
    .targetList('materials')
    .targetItem(WhereItem.IdMatchesPayload(x => x.materialId))
    .targetList('variants')
    .withReducer(Reducers.updateById());

  addVariant = this.command(this.client)
    .withAction(c => c.addVariant)
    .withSuccessMessage('Added Variant')
    .cancelConcurrent(x => [x.materialId, x.variantId])
    .targetList('materials')
    .targetItem(WhereItem.IdMatchesPayload(x => x.materialId))
    .targetList('variants')
    .withReducer(Reducers.addition());

  deleteVariant = this.command(this.client)
    .withDeferredAction(c => c.deleteVariant)
    .withSuccessMessage('Removed Variant')
    .targetList('materials')
    .targetItem(WhereItem.IdMatchesPayload(x => x.materialId))
    .targetList('variants')
    .modify(x => x.variantId)
    .withReducer(Reducers.deleteElement(x => x.variantId));

  deleteVariantAlias = this.command(this.client)
    .withDeferredAction(c => c.deleteVariantAlias)
    .withSuccessMessage('Removed Alias')
    .targetList('materials')
    .targetItem(WhereItem.IdMatchesPayload(x => x.materialId))
    .targetList('variants')
    .targetItem(WhereItem.IdMatchesPayload(x => x.materialVariantId))
    .targetList('aliases')
    .modify(x => x.aliasId)
    .withReducer(Reducers.deleteById());
  //</editor-fold>
}

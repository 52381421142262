import {isDevMode, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from "@angular/router";
import {appRoutes} from "./app.routes";
import {ServiceWorkerModule} from '@angular/service-worker';
import {NgxServiceWorkerModule} from "@juulsgaard/ngx-service-worker";
import {LocaleService} from "@lib/services/locale.service";
import {FacilityScope} from "@lib/scopes/facility.scope";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {AuthService} from "@lib/services/auth.service";
import {AuthInterceptor} from "@lib/interceptors/auth.interceptor";
import {UserScope} from "@lib/scopes/user.scope";
import {UrlInterceptor} from "@lib/interceptors/url.interceptor";
import {ExceptionInterceptor} from "@lib/interceptors/exception.interceptor";
import {AdminScope} from "@lib/scopes/admin.scope";
import {TenantScope} from "@lib/scopes/tenant.scope";
import {AsideService} from "@pack/navigation/services";
import {
  NgxDialogModule, NgxDialogOutletModule, NgxOverlayOutletModule, NgxSideMenuOutletModule, NgxSnackbarModule,
  UIScopeContext
} from "@juulsgaard/ngx-material";
import {DialogFormConfirmService} from "@lib/services/dialog-form-confirm.service";
import {FormConfirmService} from "@juulsgaard/ngx-forms-core";
import {uiScopeConfig} from "@lib/models/ui-scopes";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    NgxServiceWorkerModule.register(!isDevMode()),
    NgxSnackbarModule,
    NgxDialogModule,
    NgxDialogOutletModule,
    NgxOverlayOutletModule.WithBaseProviders(
      UIScopeContext.Provide(uiScopeConfig),
      {provide: AsideService}
    ),
    NgxSideMenuOutletModule,
  ],
  providers: [
    AuthService,
    UserScope,
    AdminScope,
    TenantScope,
    FacilityScope,
    UIScopeContext.Provide(uiScopeConfig),
    {provide: FormConfirmService, useClass: DialogFormConfirmService},
    {provide: LOCALE_ID, useFactory: (service: LocaleService) => service.locale, deps: [LocaleService]},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: UrlInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ExceptionInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

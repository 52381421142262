import {inject, Injectable} from '@angular/core';
import {ScopedStore} from "@lib/helpers/stores/scoped-store";
import {
  Color, CompositionGroup, Country, Material, MaterialVariant, TagScopeData, TenantContextStoreState, Variant
} from "@tenant/stores/context/tenant-context.models";
import {rootUserScope} from "@lib/scopes/root-scope";
import {TenantContextClient} from "@tenant/stores/context/tenant-context.client";
import {arrToLookup, arrToMap, mapArr, sortNumAsc} from "@juulsgaard/ts-tools";
import {TagData} from "@lib/models/tags";
import {Observable, tap} from "rxjs";
import {map} from "rxjs/operators";
import {Reducers} from "@lib/helpers/stores/reducers";
import {filterList} from "@juulsgaard/rxjs-tools";
import {WhereItem} from "@juulsgaard/store-service";

@Injectable()
export class TenantContextStore extends ScopedStore<TenantContextStoreState> {

  //<editor-fold desc="Selectors">

  //<editor-fold desc="Instructions">

  //<editor-fold desc="Slots">
  private _instructionSlots$ = this.selectNotNull(x => x.instructionSlots);
  instructionSlots$ = this.selector(
    this._instructionSlots$.pipe(map(list => [...list].sort(sortNumAsc(x => x.index))))
  );
  instructionSlotLookup$ = this.selector(
    this._instructionSlots$.pipe(map(mats => arrToMap(mats, x => x.id)))
  );
  //</editor-fold>

  //<editor-fold desc="Options">
  private _instructionOptions$ = this.selectNotNull(x => x.instructionText);
  instructionOptions$ = this.selector(
    this._instructionOptions$.pipe(filterList(x => !x.archived && !x.blacklisted))
  );
  blacklistableOptions = this.selector(
    this._instructionOptions$.pipe(filterList(x => !x.archived && !x.scoped))
  );
  instructionOptionLookup$ = this.selector(
    this._instructionOptions$.pipe(map(mats => arrToMap(mats, x => x.id)))
  );

  instructionSlotOptions = this.selectorFactory(
    (slotId: string) => this.instructionOptions$.pipe(map(list => list.filter(x => x.slotId === slotId)))
  );
  //</editor-fold>

  //<editor-fold desc="Icons">
  private _instructionIcons$ = this.selectNotNull(x => x.instructionIcons);
  instructionsIcons$ = this.selector(
    this._instructionIcons$.pipe(
      filterList(x => !x.archived && !x.blacklisted),
      tap(list => list.sort(sortNumAsc(x => x.index)))
    )
  );
  blacklistableIcons$ = this.selector(
    this._instructionIcons$.pipe(
      filterList(x => !x.archived)
    )
  );
  instructionIconLookup$ = this.selector(
    this._instructionIcons$.pipe(map(mats => arrToMap(mats, x => x.id)))
  );
  //</editor-fold>

  //</editor-fold>

  //<editor-fold desc="Composition">

  //<editor-fold desc="Group">
  private _materialGroups$ = this.selectNotNull(x => x.compositionGroups);
  materialGroups$ = this.selector(
    this._materialGroups$.pipe(map(list => [...list].sort(sortNumAsc(x => x.index))))
  );
  materialGroupLookup$ = this.selector(
    this._materialGroups$.pipe(map(groups => arrToMap(groups, x => x.id)))
  );
  //</editor-fold>

  //<editor-fold desc="Materials">
  private _materials$ = this.selectNotNull(x => x.materials);
  materials$ = this.selector(
    this._materials$.pipe(filterList(x => !x.archived && !x.blacklisted))
  );
  blacklistedMaterials$ = this.selector(
    this._materials$.pipe(filterList(x => !x.archived && x.blacklisted))
  );
  blacklistableMaterials$ = this.selector(
    this._materials$.pipe(filterList(x => !x.archived && !x.scoped))
  );
  materialLookup$ = this.selector(
    this._materials$.pipe(map(mats => arrToMap(mats, x => x.id)))
  );
  //</editor-fold>

  //<editor-fold desc="Variants">
  private _variants$ = this.selectNotNull(x => x.variants);
  variants$ = this.selector(
    this._variants$.pipe(filterList(x => !x.archived))
  );
  variantLookup$ = this.selector(
    this._variants$.pipe(map(mats => arrToMap(mats, x => x.id)))
  );
  //</editor-fold>

  //</editor-fold>

  //<editor-fold desc="Claims">
  private _claims$ = this.selectNotNull(x => x.claims);
  claims$ = this.selector(
    this._claims$.pipe(filterList(x => !x.archived))
  );
  claimLookup$ = this.selector(
    this._claims$.pipe(map(mats => arrToMap(mats, x => x.id)))
  );
  //</editor-fold>

  //<editor-fold desc="Languages">
  private _languages$ = this.selectNotNull(x => x.languages);
  languages$ = this.selector(this._languages$);
  languageLookup$ = this.selector(
    this._languages$.pipe(map(mats => arrToMap(mats, x => x.id)))
  );
  //</editor-fold>

  //<editor-fold desc="Countries">
  private _countries$ = this.selectNotNull(x => x.countries);
  countries$ = this.selector(this._countries$);
  countryLookup$ = this.selector(
    this._countries$.pipe(map(mats => arrToMap(mats, x => x.id)))
  );
  //</editor-fold>

  //<editor-fold desc="Colors">
  private _colors$ = this.selectNotNull(x => x.colors);
  colors$ = this.selector(
    this._colors$.pipe(map(colors => colors.filter(x => !x.archived)))
  );
  colorLookup$ = this.selector(
    this._colors$.pipe(map(colors => arrToMap(colors, x => x.id)))
  );
  //</editor-fold>

  //<editor-fold desc="Tags">
  tagsScopes$: Observable<TagScopeData[]> = this.selectNotNull(({tags, tagScopes}) => {
    if (!tags || !tagScopes) return undefined;
    const tagLookup = arrToLookup(tags, x => x.scopeId);
    return tagScopes.map(scope => ({...scope, tags: tagLookup.get(scope.id) ?? []}) satisfies TagScopeData);
  });
  tags$: Observable<TagData[]> = this.selectNotNull(({tags, tagScopes}) => {
    if (!tags || !tagScopes) return undefined;
    const scopes = arrToMap(tagScopes, x => x.id);

    return mapArr(tags, tag => {
      const scope = scopes.get(tag.scopeId);
      if (!scope) return undefined;
      return ({...tag, scopeName: scope.name, color: scope.color}) satisfies TagData;
    })
  });
  //</editor-fold>

  //<editor-fold desc="Templates">
  private _templates$ = this.selectNotNull(x => x.templates);
  templates$ = this._templates$;
  templateLookup$ = this.selector(
    this._templates$.pipe(map(list => arrToMap(list, x => x.id)))
  );
  //</editor-fold>

  //<editor-fold desc="Templates">
  private _iconSets$ = this.selectNotNull(x => x.iconSets);
  iconSets$ = this._iconSets$;
  iconSetLookup$ = this.selector(
    this._iconSets$.pipe(map(list => arrToMap(list, x => x.id)))
  );
  //</editor-fold>

  //</editor-fold>

  constructor(private client: TenantContextClient) {
    super({}, inject(rootUserScope));
  }

  //<editor-fold desc="Instructions">
  loadInstructions = this.command(this.client)
    .withAction(c => c.loadInstructions)
    .isInitial()
    .withReducer(({options, slots, icons}) => ({instructionSlots: slots, instructionText: options, instructionIcons: icons}));
  //</editor-fold>

  //<editor-fold desc="Materials">
  loadMaterials = this.command(this.client)
    .withAction(c => c.loadMaterials)
    .isInitial()
    .withReducer(({materials, groups, variants}) => ({materials, compositionGroups: groups, variants}));

  _addCompositionGroup = this.command()
    .withPayload<CompositionGroup>()
    .targetList('compositionGroups')
    .withReducer(Reducers.addition());

  _addMaterial = this.command()
    .withPayload<Material>()
    .targetList('materials')
    .withReducer(Reducers.addition());

  _addVariant = this.command()
    .withPayload<Variant>()
    .targetList('variants')
    .withReducer(Reducers.addition());

  _addMaterialVariant = this.command()
    .withPayload<MaterialVariant&{materialId: string}>()
    .targetList('materials')
    .targetItem(WhereItem.IdMatchesPayload(x => x.materialId))
    .targetList('variants')
    .withReducer(Reducers.addition());

  _removeMaterialFromBlacklist = this.command()
    .withPayload<string>()
    .targetList('materials')
    .targetItem(WhereItem.IdMatchesPayload())
    .withReducer(() => ({blacklisted: false}));

  _removeVariantFromBlacklist = this.command()
    .withPayload<{materialId: string, variantId: string}>()
    .targetList('materials')
    .targetItem(WhereItem.IdMatchesPayload(x => x.materialId))
    .targetList('variants')
    .targetItem(WhereItem.IdMatchesPayload(x => x.variantId))
    .withReducer(() => ({blacklisted: false}));
  //</editor-fold>

  //<editor-fold desc="Claims">
  loadClaims = this.command(this.client)
    .withAction(c => c.loadClaims)
    .isInitial()
    .withReducer(claims => ({claims}));
  //</editor-fold>

  //<editor-fold desc="Languages">
  loadLanguages = this.command(this.client)
    .withAction(c => c.loadLanguages)
    .isInitial()
    .withReducer(languages => ({languages}));
  //</editor-fold>

  //<editor-fold desc="Countries">
  loadCountries = this.command(this.client)
    .withAction(c => c.loadCountries)
    .isInitial()
    .withReducer(countries => ({countries}));

  addCountry = this.command()
    .withPayload<Country>()
    .targetList('countries')
    .withReducer(Reducers.addition());
  //</editor-fold>

  //<editor-fold desc="Tags">
  loadTags = this.command(this.client)
    .withAction(c => c.loadTags)
    .isInitial()
    .withReducer(({tags, scopes}) => ({tags, tagScopes: scopes}));
  //</editor-fold>

  //<editor-fold desc="Colors">
  loadColors = this.command(this.client)
    .withAction(c => c.loadColors)
    .isInitial()
    .withReducer(colors => ({colors}));

  addColor = this.command()
    .withPayload<Color>()
    .targetList('colors')
    .withReducer(Reducers.addition());
  //</editor-fold>

  loadIconSets = this.command(this.client)
    .withAction(c => c.loadIconSets)
    .isInitial()
    .withReducer(iconSets => ({iconSets}));

  loadTemplates = this.command(this.client)
    .withAction(c => c.loadTemplates)
    .isInitial()
    .withReducer(templates => ({templates}));
}

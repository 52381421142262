import {UIScopeConfig} from "@juulsgaard/ngx-material";

export const uiScopeConfig: UIScopeConfig = {
  default: {
    showMenu: true,
    class: 'main',
    child: {
      class: 'sub',
      tabScope: {
        class: 'tab',
        child: {
          class: 'sub-tab'
        }
      }
    }
  }
}

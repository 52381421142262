import {Injectable} from '@angular/core';
import {ScopedStore} from "@lib/helpers/stores/scoped-store";
import {AdminScope} from "@lib/scopes/admin.scope";
import {LanguageClient} from "@admin/stores/languages/language.client";
import {LanguageStoreState} from "@admin/stores/languages/language.models";
import {Reducers} from "@lib/helpers/stores/reducers";
import {map} from "rxjs/operators";
import {arrToMap} from "@juulsgaard/ts-tools";

@Injectable()
export class LanguageStore extends ScopedStore<LanguageStoreState> {

  languages$ = this.selectNotNull(x => x.languages);
  languageLookup$ = this.selector(
    this.languages$.pipe(map(list => arrToMap(list, x => x.id)))
  );

  countries$ = this.selectNotNull(x => x.countries);

  constructor(scope: AdminScope, private client: LanguageClient) {
    super({}, scope);
  }

  loadAll = this.command(this.client)
    .withAction(c => c.loadAdminLanguages)
    .isInitial()
    .withReducer(x => x);

  //<editor-fold desc="Languages">

  createLanguage = this.command(this.client)
    .withAction(c => c.createLanguage)
    .withSuccessMessage('Added a Language')
    .targetList('languages')
    .withReducer(Reducers.addition());

  updateLanguage = this.command(this.client)
    .withAction(c => c.updateLanguage)
    .withSuccessMessage('Updated Language')
    .targetList('languages')
    .withReducer(Reducers.updateById());

  moveLanguage = this.command(this.client)
    .withDeferredAction(c => c.moveLanguage)
    .targetList('languages')
    .withReducer(Reducers.moveById());

  deleteLanguage = this.command(this.client)
    .withDeferredAction(c => c.deleteLanguage)
    .withSuccessMessage('Deleted a Language')
    .targetList('languages')
    .withReducer(Reducers.deleteById());

  //</editor-fold>

  //<editor-fold desc="Countries">

  createCountry = this.command(this.client)
    .withAction(c => c.createCountry)
    .withSuccessMessage('Added a Country')
    .targetList('countries')
    .withReducer(Reducers.addition());

  updateCountry = this.command(this.client)
    .withAction(c => c.updateCountry)
    .withSuccessMessage('Updated Country')
    .targetList('countries')
    .withReducer(Reducers.updateById());

  deleteCountry = this.command(this.client)
    .withDeferredAction(c => c.deleteCountry)
    .withSuccessMessage('Deleted a Country')
    .targetList('countries')
    .withReducer(Reducers.deleteById());

  //</editor-fold>
}

import {Injectable} from "@angular/core";
import {ScopedStore} from "@lib/helpers/stores/scoped-store";
import {TenantScopeState} from "@tenant/stores/scope/tenant-scope.models";
import {TenantScopeClient} from "@tenant/stores/scope/tenant-scope.client";
import {TenantScope} from "@lib/scopes/tenant.scope";

@Injectable()
export class TenantScopeStore extends ScopedStore<TenantScopeState> {

  tenant$ = this.selectNotNull(x => x.tenant);

  constructor(scope: TenantScope, private client: TenantScopeClient) {
    super({}, scope);
  }

  loadTenant = this.command(this.client)
    .withAction(c => c.loadTenant)
    .isInitial()
    .withReducer(tenant => ({tenant}));
}

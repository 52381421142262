import {Injectable} from '@angular/core';
import {ScopedStore} from "@lib/helpers/stores/scoped-store";
import {FacilityScopeState} from "@facility/stores/scope/facility-scope.models";
import {FacilityScope} from "@lib/scopes/facility.scope";
import {FacilityScopeClient} from "@facility/stores/scope/facility-scope.client";

@Injectable()
export class FacilityScopeStore extends ScopedStore<FacilityScopeState> {

  facility$ = this.selectNotNull(x => x.facility);

  constructor(scope: FacilityScope, private client: FacilityScopeClient) {
    super({}, scope);
  }

  loadFacility = this.command(this.client)
    .withAction(c => c.loadFacility)
    .isInitial()
    .withReducer(facility => ({facility}));
}

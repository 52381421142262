import {Injectable} from '@angular/core';
import {ScopedStore} from "@lib/helpers/stores/scoped-store";
import {
  AdminImportProductMaterialVariant, ImportProductsUnloadModel, ImportStoreState, ImportValidationUnloadModel
} from "@tenant/stores/imports/import.models";
import {ImportClient} from "@tenant/stores/imports/import.client";
import {Parsers} from "@lib/helpers/stores/parsers";
import {Reducers} from "@lib/helpers/stores/reducers";
import {WhereItem} from "@juulsgaard/store-service";
import {arrToMap, arrToSet, MapFunc} from "@juulsgaard/ts-tools";
import {updateListItems} from "@lib/helpers/stores/reducer-utils";
import {TenantScope} from "@lib/scopes/tenant.scope";

@Injectable()
export class ImportStore extends ScopedStore<ImportStoreState> {

  imports$ = this.selectNotNull(x => x.imports);

  constructor(scope: TenantScope, private client: ImportClient) {
    super({}, scope);
  }

  private invalidateImport = this.command()
    .withPayload<string>()
    .targetList('imports')
    .targetItem(WhereItem.IdMatchesPayload())
    .withReducer(() => ({validatedAt: undefined}));

  //<editor-fold desc="Import Load">
  loadImports = this.command(this.client)
    .withAction(c => c.loadImports)
    .isInitial()
    .withParser(Parsers.dates(true))
    .withReducer((imports) => ({imports}));

  loadImportValidation = this.command(this.client)
    .withAction(c => c.loadImportValidation)
    .cancelConcurrent(x => x)
    .targetList('imports')
    .withReducer(Reducers.updateById());

  loadImportProducts = this.command(this.client)
    .withAction(c => c.loadImportProducts)
    .cancelConcurrent(x => x)
    .targetList('imports')
    .withReducer(Reducers.updateById());
  //</editor-fold>

  //<editor-fold desc="Imports">
  createImport = this.command(this.client)
    .withAction(c => c.createImport)
    .withSuccessMessage('Created new Import')
    .targetList('imports')
    .withReducer(Reducers.addition());

  reUploadImport = this.command(this.client)
    .withAction(c => c.reImport)
    .withSuccessMessage('Re-uploaded the import file')
    .cancelConcurrent(x => x.importId)
    .targetList('imports')
    .withReducer(Reducers.replaceById());

  updateImport = this.command(this.client)
    .withAction(c => c.updateImport)
    .withSuccessMessage('Updated Import')
    .targetList('imports')
    .withReducer(Reducers.updateById());

  deleteImport = this.command(this.client)
    .withDeferredAction(c => c.deleteImport)
    .withSuccessMessage('Deleted Import')
    .targetList('imports')
    .withReducer(Reducers.deleteById());

  approveImport = this.command(this.client)
    .withAction(c => c.approveImport)
    .withSuccessMessage('Import was Approved')
    .cancelConcurrent(x => x)
    .targetList('imports')
    .targetItem(WhereItem.IdMatchesPayload())
    .withReducer((data) => ({
      ...unloadValidation,
      ...data,
      productsLoaded: true
    }));

  unApproveImport = this.command(this.client)
    .withAction(c => c.unApproveImport)
    .withSuccessMessage('Import was Unapproved')
    .cancelConcurrent(x => x)
    .targetList('imports')
    .withReducer(Reducers.replaceById());

  completeImport = this.command(this.client)
    .withAction(c => c.completeImport)
    .withSuccessMessage('Import was Completed')
    .targetList('imports')
    .modify((_, id) => id)
    .withReducer(Reducers.deleteById());
  //</editor-fold>

  //<editor-fold desc="Columns">
  updateColumns = this.command(this.client)
    .withAction(c => c.updateColumns)
    .withSuccessMessage('Updated Columns')
    .withAfterEffect((_, {id}) => this.invalidateImport.emit(id))
    .targetList('imports')
    .withReducer(Reducers.updateById());

  updateColumn = this.command(this.client)
    .withAction(c => c.updateColumn)
    .cancelConcurrent(x => x.id)
    .withSuccessMessage('Updated Column')
    .withAfterEffect((_, {importId}) => this.invalidateImport.emit(importId))
    .targetList('imports')
    .targetItem(WhereItem.IdMatchesPayload(x => x.importId))
    .targetList('columns')
    .withReducer(Reducers.updateById());
  //</editor-fold>

  //<editor-fold desc="Rows">
  validateRows = this.command(this.client)
    .withAction(c => c.validateRows)
    .withSuccessMessage('Tables Verified')
    .cancelConcurrent(x => x)
    .targetList('imports')
    .targetItem(WhereItem.IdMatchesPayload())
    .withReducer((newRows, {tables}) => {

      const rowLookup = arrToMap(newRows, x => x.id);

      tables = tables?.map(t => ({
        ...t,
        rows: t.rows.map(r => {
          const row = rowLookup.get(r.id);
          if (!row) return r;
          return {...r, ...row};
        })
      }));

      return {
        validatedAt: new Date().toString(),
        tables
      }
    });

  toggleTableRow = this.command(this.client)
    .withDeferredAction(c => c.toggleTableRow)
    .withSuccessMessage('Updated Row')
    .targetList('imports')
    .targetItem(WhereItem.IdMatchesPayload(x => x.grandparentId))
    .targetList('tables')
    .targetItem(WhereItem.IdMatchesPayload(x => x.parentId))
    .targetList('rows')
    .targetItem(WhereItem.IdMatchesPayload(x => x.id))
    .withReducer(({value}, {ignored}) => ({ignored: value ?? !ignored}))
  //</editor-fold>

  //<editor-fold desc="Tables">
  updateTable = this.command(this.client)
    .withAction(c => c.updateTable)
    .withSuccessMessage('Updated Table')
    .targetList('imports')
    .targetItem(WhereItem.IdMatchesPayload(x => x.importId))
    .targetList('tables')
    .withReducer(Reducers.updateById());

  toggleTable = this.command(this.client)
    .withDeferredAction(c => c.toggleTable)
    .withSuccessMessage('Updated Table')
    .withAfterEffect((_, {parentId}) => this.invalidateImport.emit(parentId))
    .targetList('imports')
    .targetItem(WhereItem.IdMatchesPayload(x => x.parentId))
    .targetList('tables')
    .targetItem(WhereItem.IdMatchesPayload(x => x.id))
    .withReducer(({value}, {ignored}) => ({ignored: value ?? !ignored}))

  //</editor-fold>

  updateProductColor = this.command(this.client)
    .withAction(c => c.updateProductColor)
    .withSuccessMessage('Updated Color')
    .targetList('imports')
    .targetItem(WhereItem.IdMatchesPayload(x => x.importId))
    .targetList('products')
    .withReducer((affected, products, {id}) => {
      const ids = arrToSet(affected.map(x => x.id));
      return updateListItems(products, x => ids.has(x.id), {colorId: id, colorModified: true})
    });

  updateProductOrigin = this.command(this.client)
    .withAction(c => c.updateProductOrigin)
    .withSuccessMessage('Updated Origin')
    .targetList('imports')
    .targetItem(WhereItem.IdMatchesPayload(x => x.importId))
    .targetList('products')
    .withReducer((affected, products, {id}) => {
      const ids = arrToSet(affected.map(x => x.id));
      return updateListItems(products, x => ids.has(x.id), {originId: id, originModified: true})
    });

  updateProductFacility = this.command(this.client)
    .withAction(c => c.updateProductFacility)
    .withSuccessMessage('Updated Facility')
    .targetList('imports')
    .targetItem(WhereItem.IdMatchesPayload(x => x.importId))
    .targetList('products')
    .withReducer((affected, products, {id}) => {
      const ids = arrToSet(affected.map(x => x.id));
      return updateListItems(products, x => ids.has(x.id), {facilityId: id, facilityModified: true})
    });

  updateProductCompositionGroup = this.command(this.client)
    .withAction(c => c.updateCompositionGroup)
    .withSuccessMessage('Updated Composition Group')
    .targetList('imports')
    .targetItem(WhereItem.IdMatchesPayload(x => x.importId))
    .targetList('products')
    .withReducer((allAffected, products, {id}) => {
      const affected = arrToSet(allAffected.map(x => x.id));
      return products.map(product => ({
          ...product,
          composition: updateListItems(product.composition, x => affected.has(x.id), {compositionGroupId: id, modified: true})
        }));
    });

  updateProductMaterial = this.command(this.client)
    .withAction(c => c.updateMaterial)
    .withSuccessMessage('Updated Material')
    .targetList('imports')
    .targetItem(WhereItem.IdMatchesPayload(x => x.importId))
    .targetList('products')
    .withReducer((allAffected, products, {materialId, variantId}) => {
      const affected = arrToMap(allAffected, x => x.productMaterialId, x => x.productMaterialVariantId);
      return products.map(product => ({
        ...product,
        composition: product.composition.map(composition => ({
          ...composition,
          materials: composition.materials.map(material => {
            if (!affected.has(material.id)) return material;
            const productVariantId = affected.get(material.id);
            const variants: AdminImportProductMaterialVariant[] = productVariantId && variantId
              ? [{variantId, percentage: 100, id: productVariantId, modified: true}]
              : material.variants;
            return {...material, materialId, modified: true, variants};
          })
        }))
      }));
    });

  updateProductVariant = this.command(this.client)
    .withAction(c => c.updateVariant)
    .withSuccessMessage('Updated Variant')
    .targetList('imports')
    .targetItem(WhereItem.IdMatchesPayload(x => x.importId))
    .targetList('products')
    .withReducer((allAffected, products, {variantId}) => {
      const affected = arrToSet(allAffected.map(x => x.id));
      return products.map(product => ({
        ...product,
        composition: product.composition.map(composition => ({
          ...composition,
          materials: composition.materials.map(material => ({
            ...material,
            variants: updateListItems(material.variants, x => affected.has(x.id), {variantId, modified: true})
          }))
        }))
      }));
    });

  updateProductInstructionGroup = this.command(this.client)
    .withAction(c => c.updateInstruction)
    .withSuccessMessage('Updated Instruction')
    .targetList('imports')
    .targetItem(WhereItem.IdMatchesPayload(x => x.importId))
    .targetList('products')
    .withReducer((allAffected, products, {id}) => {
      const affected = arrToSet(allAffected.map(x => x.id));
      return products.map(product => ({
        ...product,
        instructions: updateListItems(product.instructions, x => affected.has(x.id), {instructionTextId: id, modified: true})
      }));
    });
}

const unloadValidation: ImportValidationUnloadModel = {
  validatedAt: undefined,
  tables: undefined,
  columns: undefined,
  validationLoaded: undefined
};

const unloadProducts: ImportProductsUnloadModel = {
  products: undefined,
  productsLoaded: undefined,
  approvedBy: undefined
};


function arrToSetLookup<T, TKey, TVal>(list: T[], getKey: MapFunc<T, TKey>, getVal: MapFunc<T, TVal>) {
  const result = new Map<TKey, Set<TVal>>();
  for (let item of list) {

    const key = getKey(item);

    let set = result.get(key);
    if (!set) {
      set = new Set<TVal>();
      result.set(key, set);
    }

    const val = getVal(item);
    set.add(val);
  }

  return result;
}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from "../services/auth.service";

@Injectable({providedIn: 'root'})
export class LoggedInGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authService.hasRefreshToken) {
      console.debug('User is not logged in', '- Redirecting to login page');
      sessionStorage.setItem('attemptedUrl', state.url);
      return this.router.parseUrl('/login');
    }

    if (this.authService.isAdmin) {
      if (route.firstChild?.data?.['root']) {
        console.debug('User is Admin', '- Redirecting to admin page');
        return this.router.parseUrl('/admin');
      }

      console.debug('User is Admin', '- Sending through')
      return true;
    }

    console.debug('User is logged in', '- Sending through')
    return true;
  }
}

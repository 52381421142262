import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  FacilityAdminLoadModel, FacilityCreateModel, FacilityListModel, FacilityResponseModel, FacilityUpdateModel
} from "@admin/stores/tenants/tenant.models";
import {AliasTargetModel} from "@lib/models/alias.models";
import {tap} from "rxjs";

@Injectable({providedIn: 'root'})
export class FacilityClient {
  constructor(private httpClient: HttpClient) {
  }

  //<editor-fold desc="Facility Load">

  loadFacilities() {
    return this.httpClient.get<FacilityListModel[]>(`tenant://facilities`);
  }

  loadArchivedFacilities() {
    return this.httpClient.get<FacilityListModel[]>(`tenant://facilities/archived`);
  }

  loadFacility(facilityId: string) {
    return this.httpClient.get<FacilityAdminLoadModel>(`admin://facility/${facilityId}/admin`)
      .pipe(tap(x => x.loaded = true));
  }

  loadFacilityForTenant(facilityId: string) {
    return this.httpClient.get<FacilityAdminLoadModel>(`tenant://tenant/facility/${facilityId}/admin`)
      .pipe(tap(x => x.loaded = true));
  }

  //</editor-fold>

  //<editor-fold desc="Facility">

  createFacility({tenantId, ...data}: FacilityCreateModel) {
    return this.httpClient.post<FacilityListModel>(`admin://tenant/${tenantId}/facilities`, data);
  }

  updateFacility({id, ...data}: FacilityUpdateModel) {
    return this.httpClient.put<FacilityResponseModel>(`admin://facility/${id}`, data);
  }

  archiveFacility(facilityId: string) {
    return this.httpClient.delete<void>(`admin://facility/${facilityId}`);
  }

  deleteAlias({parentId, aliasId}: AliasTargetModel) {
    return this.httpClient.delete<void>(`admin://facility/${parentId}/alias/${aliasId}`);
  }

  restoreFacility(facilityId: string) {
    return this.httpClient.post<FacilityListModel>(`admin://facility/${facilityId}/restore`, {});
  }

  //</editor-fold>
}

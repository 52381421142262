import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AdminColor, ColorCreateModel, ColorUpdateModel} from "@tenant/stores/colors/color.models";

@Injectable({providedIn: 'root'})
export class ColorClient {

  constructor(private httpClient: HttpClient) {
  }

  //<editor-fold desc="Color">

  createColor(data: ColorCreateModel) {
    return this.httpClient.post<AdminColor>(`tenant://tenant/colors`, data);
  }

  updateColor({id, ...data}: ColorUpdateModel) {
    return this.httpClient.put<AdminColor>(`tenant://tenant/color/${id}`, data);
  }

  archiveColor(colorId: string) {
    return this.httpClient.delete<void>(`tenant://tenant/color/${colorId}`);
  }

  //</editor-fold>

  //<editor-fold desc="Color Load">

  loadColors() {
    return this.httpClient.get<AdminColor[]>(`tenant://tenant/colors/admin`);
  }

  //</editor-fold>

}

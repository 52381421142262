import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {PrintRequestCreateModel, PrintRequestListModel, PrintRequestsLoadModel} from "@admin/stores/print/print.models";

@Injectable({providedIn: 'root'})
export class PrintClient {
  constructor(private httpClient: HttpClient) {
  }

  //<editor-fold desc="Print">

  reprintProduct({productId, ...data}: PrintRequestCreateModel) {
    return this.httpClient.post<PrintRequestListModel>(`tenant://tenant/product/${productId}/reprint`, data);
  }

  markAsPrinted(printId: string) {
    return this.httpClient.post<void>(`admin://print/${printId}/printed`, {});
  }

  markAsPrintedTenant({id, templateId}: {id: string, templateId?: string}) {
    const params = new HttpParams();
    if (templateId) params.set('templateId', templateId);
    return this.httpClient.post<string[]>(`admin://print/tenant/${id}/printed`, {}, {params});
  }

  markAsPrintedFacility({id, templateId}: {id: string, templateId?: string}) {
    const params = new HttpParams();
    if (templateId) params.set('templateId', templateId);
    return this.httpClient.post<string[]>(`admin://print/facility/${id}/printed`, {}, {params});
  }

  cancelPrint(printId: string) {
    return this.httpClient.delete<void>(`admin://print/${printId}`);
  }

  //</editor-fold>

  //<editor-fold desc="Print Load">

  loadPrintRequests() {
    return this.httpClient.get<PrintRequestsLoadModel>(`admin://prints/admin`);
  }

  //</editor-fold>
}

import {Injectable} from '@angular/core';
import {ScopedStore} from "@lib/helpers/stores/scoped-store";
import {AdminUserStoreState} from "@admin/stores/admins/admin-user.models";
import {AdminScope} from "@lib/scopes/admin.scope";
import {AdminUserClient} from "@admin/stores/admins/admin-user.client";
import {Reducers} from "@lib/helpers/stores/reducers";
import {WhereItem} from "@juulsgaard/store-service";

@Injectable()
export class AdminUserStore extends ScopedStore<AdminUserStoreState> {

  admins$ = this.selectNotNull(x => x.users);

  constructor(scope: AdminScope, private client: AdminUserClient) {
    super({}, scope);
  }

  loadAdmins = this.command(this.client)
    .withAction(c => c.loadAdmins)
    .isInitial()
    .withReducer(users => ({users}));

  loadAdmin = this.command(this.client)
    .withAction(c => c.loadAdmin)
    .cancelConcurrent(x => x)
    .targetList('users')
    .withReducer(Reducers.updateById());

  createAdmin = this.command(this.client)
    .withAction(c => c.createAdmin)
    .withSuccessMessage('Created new Admin Account')
    .targetList('users')
    .withReducer(Reducers.addition());

  updateAdmin = this.command(this.client)
    .withAction(c => c.updateAdmin)
    .withSuccessMessage('Updated Admin Account')
    .targetList('users')
    .withReducer(Reducers.updateById());

  requestPassword = this.command(this.client)
    .withDeferredAction(c => c.requestPassword)
    .withSuccessMessage('Requested new password')
    .targetList('users')
    .targetItem(WhereItem.IdMatchesPayload())
    .withReducer(() => ({requiresNewPassword: true}));

  resetLoginAttempts = this.command(this.client)
    .withDeferredAction(c => c.resetLoginAttempts)
    .withSuccessMessage('Reset failed login attempts')
    .targetList('users')
    .targetItem(WhereItem.IdMatchesPayload())
    .withReducer(() => ({failedLoginAttempts: 0, lockedOut: false}));

  updatePassword = this.command(this.client)
    .withDeferredAction(c => c.updatePassword)
    .withSuccessMessage('Updated password')
    .targetList('users')
    .targetItem(WhereItem.IdMatchesPayload(x => x.userId))
    .withReducer(() => ({lastPasswordReset: new Date().toString(), hasPassword: true}));

  removeAdmin = this.command(this.client)
    .withDeferredAction(c => c.removeAdmin)
    .withSuccessMessage('Admin Account was Removed')
    .targetList('users')
    .withReducer(Reducers.deleteById());
}

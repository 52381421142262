import {inject, Injectable} from '@angular/core';
import {ScopedStore} from "@lib/helpers/stores/scoped-store";
import {TenantScope} from "@lib/scopes/tenant.scope";
import {TenantDashboardClient} from "@tenant/stores/dashboard/tenant-dashboard.client";
import {TenantDashboardStoreState} from "@tenant/stores/dashboard/tenant-dashboard.models";

@Injectable()
export class TenantDashboardStore extends ScopedStore<TenantDashboardStoreState> {

  reviews$ = this.selectNotNull(x => x.reviews);

  products$ = this.selectNotNull(x => x.products?.facilityProducts);
  productTotal$ = this.selectNotNull(({products}) => {
    if (!products) return undefined;
    const active = Object.values(products.facilityProducts).reduce((acc, stats) => acc + stats.active, 0);
    return {active};
  });

  constructor(private client: TenantDashboardClient) {
    super({}, inject(TenantScope));
  }

  loadReviewStats = this.command(this.client)
    .withAction(c => c.loadReviewStats)
    .isInitial()
    .withReducer(reviews => ({reviews}));

  loadProductStats = this.command(this.client)
    .withAction(c => c.loadTenantProductStats)
    .isInitial()
    .withReducer(products => ({products}));
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  ChildToggleModel, GrandChildToggleModel, ImportAffectedModel, ImportColumnResponseModel, ImportColumnsResponseModel,
  ImportColumnsUpdateModel, ImportColumnUpdateModel, ImportCreateModel, ImportListModel, ImportMaterialAffectedModel,
  ImportProductAliasPatchModel, ImportProductCompositionAliasPatchModel, ImportProductIdPatchModel,
  ImportProductInstructionAliasPatchModel, ImportProductMaterialUpdateModel, ImportProductMaterialVariantUpdateModel,
  ImportProductsLoadModel, ImportResponseModel, ImportTableResponseModel, ImportTableRowResponseModel,
  ImportTableUpdateModel, ImportUpdateModel, ImportUploadModel, ImportValidationLoadModel
} from "@tenant/stores/imports/import.models";
import {tap} from "rxjs";
import {sortNumAsc} from "@juulsgaard/ts-tools";


@Injectable({providedIn: 'root'})
export class ImportClient {
  constructor(private httpClient: HttpClient) {
  }


  //<editor-fold desc="Import">

  createImport(data: ImportCreateModel) {
    const formData = new FormData();
    formData.set('file', data.file);
    return this.httpClient.post<ImportListModel>(`tenant://tenant/imports`, formData);
  }

  reImport({importId, file}: ImportUploadModel) {
    const formData = new FormData();
    formData.set('file', file);
    return this.httpClient.post<ImportListModel>(`tenant://tenant/import/${importId}/upload`, formData);
  }

  updateImport({id, ...data}: ImportUpdateModel) {
    return this.httpClient.put<ImportResponseModel>(`tenant://tenant/import/${id}`, data);
  }

  deleteImport(id: string) {
    return this.httpClient.delete<void>(`tenant://tenant/import/${id}`);
  }

  approveImport(id: string) {
    return this.httpClient.post<ImportProductsLoadModel>(`tenant://tenant/import/${id}/approve`, {});
  }

  unApproveImport(importId: string) {
    return this.httpClient.post<ImportListModel>(`tenant://tenant/import/${importId}/unapprove`, {});
  }

  completeImport(id: string) {
    return this.httpClient.post<void>(`tenant://tenant/import/${id}/complete`, {});
  }

  //</editor-fold>

  //<editor-fold desc="Import Load">

  loadImports() {
    return this.httpClient.get<ImportListModel[]>(`tenant://tenant/imports`);
  }

  loadImportValidation(importId: string) {
    return this.httpClient.get<ImportValidationLoadModel>(`tenant://tenant/import/${importId}/validation`)
      .pipe(tap(x => {
        x.validationLoaded = true;
        x.tables.sort(sortNumAsc(x => x.index));
        x.tables.forEach(t => t.rows.sort(sortNumAsc(x => x.index)));
        x.columns.sort(sortNumAsc(x => x.index));
      }));
  }

  loadImportProducts(importId: string) {
    return this.httpClient.get<ImportProductsLoadModel>(`tenant://tenant/import/${importId}/products`)
      .pipe(tap(x => {
        x.productsLoaded = true;
      }));
  }

  //</editor-fold>

  updateColumns({id, ...data}: ImportColumnsUpdateModel) {
    return this.httpClient.put<ImportColumnsResponseModel>(`tenant://tenant/import/${id}/columns`, data);
  }

  updateColumn({importId, id, ...data}: ImportColumnUpdateModel) {
    return this.httpClient.put<ImportColumnResponseModel>(`tenant://tenant/import/column/${id}`, data);
  }

  validateRows(importId: string) {
    return this.httpClient.post<ImportTableRowResponseModel[]>(`tenant://tenant/import/${importId}/rows/validate`, {});
  }

  updateTable({importId, id, ...data}: ImportTableUpdateModel) {
    return this.httpClient.put<ImportTableResponseModel>(`tenant://tenant/import/table/${id}`, data);
  }

  toggleTable({parentId, id, ...data}: ChildToggleModel) {
    return this.httpClient.patch<void>(`tenant://tenant/import/table/${id}/toggle`, data);
  }

  toggleTableRow({grandparentId, parentId, id, ...data}: GrandChildToggleModel) {
    return this.httpClient.patch<void>(`tenant://tenant/import/table/row/${id}/toggle`, data);
  }

  updateProductColor({productId, ...data}: ImportProductIdPatchModel) {
    return this.httpClient.patch<ImportAffectedModel[]>(`tenant://tenant/import/product/${productId}/color`, data);
  }

  updateProductOrigin({productId, ...data}: ImportProductIdPatchModel) {
    return this.httpClient.patch<ImportAffectedModel[]>(`tenant://tenant/import/product/${productId}/origin`, data);
  }

  updateProductFacility({productId, ...data}: ImportProductAliasPatchModel) {
    return this.httpClient.patch<ImportAffectedModel[]>(`tenant://tenant/import/product/${productId}/facility`, data);
  }

  updateCompositionGroup({importId, productId, compositionId, ...data}: ImportProductCompositionAliasPatchModel) {
    return this.httpClient.patch<ImportAffectedModel[]>(`tenant://tenant/import/product/composition/${compositionId}/group`, data);
  }

  updateMaterial({productMaterialId, ...data}: ImportProductMaterialUpdateModel) {
    return this.httpClient.patch<ImportMaterialAffectedModel[]>(`tenant://tenant/import/product/composition/material/${productMaterialId}`, data);
  }

  updateVariant({productVariantId, ...data}: ImportProductMaterialVariantUpdateModel) {
    return this.httpClient.patch<ImportAffectedModel[]>(`tenant://tenant/import/product/composition/material/variant/${productVariantId}`, data);
  }

  updateInstruction({instructionId, ...data}: ImportProductInstructionAliasPatchModel) {
    return this.httpClient.patch<ImportAffectedModel[]>(`tenant://tenant/import/product/instruction/${instructionId}`, data);
  }

}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  ProductCompositionResponseModel, ProductCompositionUpdateModel, ProductCreateModel, ProductInstructionsResponseModel,
  ProductInstructionSuggestion, ProductInstructionsUpdateModel, ProductListModel, ProductLoadModel,
  ProductResponseModel, ProductUpdateModel
} from "@facility/stores/products/product.models";
import {tap} from "rxjs";
import {ProductReview} from "@tenant/stores/reviews/review.models";

@Injectable({providedIn: 'root'})
export class ProductClient {
  constructor(private httpClient: HttpClient) {
  }

  //<editor-fold desc="Product Load">

  loadAllProducts() {
    return this.httpClient.get<ProductListModel[]>(`facility://facility/products/all`);
  }

  loadProduct(productId: string) {
    return this.httpClient.get<ProductLoadModel>(`facility://facility/product/${productId}`)
      .pipe(tap(x => x.loaded = true));
  }

  loadReviewHistory(productId: string) {
    return this.httpClient.get<ProductReview[]>(`facility://facility/product/${productId}/reviews`);
  }

  //</editor-fold>

  //<editor-fold desc="Product">

  createProduct(data: ProductCreateModel) {
    return this.httpClient.post<ProductListModel>(`facility://facility/products`, data);
  }

  updateProduct({id, ...data}: ProductUpdateModel) {
    return this.httpClient.put<ProductResponseModel>(`facility://product/${id}`, data);
  }

  archiveProduct(productId: string) {
    return this.httpClient.delete<void>(`facility://facility/product/${productId}`);
  }

  //</editor-fold>

  getInstructionSuggestions(id: string) {
    return this.httpClient.get<ProductInstructionSuggestion[]>(`facility://facility/product/${id}/instruction/suggestions`);
  }

  updateComposition({id, ...data}: ProductCompositionUpdateModel) {
    return this.httpClient.put<ProductCompositionResponseModel>(`facility://product/${id}/composition`, data);
  }

  updateInstructions({id, ...data}: ProductInstructionsUpdateModel) {
    return this.httpClient.put<ProductInstructionsResponseModel>(`facility://product/${id}/instructions`, data);
  }

  submitProduct(productId: string) {
    return this.httpClient.post<void>(`facility://facility/product/${productId}/submit`, {});
  }
}

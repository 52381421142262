import {Component, OnInit} from '@angular/core';
import {NgxServiceWorkerModule, ServiceWorkerService} from "@juulsgaard/ngx-service-worker";
import {RouterLink} from "@angular/router";
import {MatRippleModule} from "@angular/material/core";

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  imports: [
    RouterLink,
    MatRippleModule,
    NgxServiceWorkerModule
  ],
  standalone: true
})
export class PageNotFoundComponent implements OnInit {

  constructor(public swService: ServiceWorkerService) { }

  async ngOnInit() {
    await this.swService.checkForUpdate();
  }

}

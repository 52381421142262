import {inject, Injectable} from '@angular/core';
import {ScopedStore} from "@lib/helpers/stores/scoped-store";
import {ReviewState} from "@tenant/stores/reviews/review.models";
import {TenantScope} from "@lib/scopes/tenant.scope";
import {ReviewClient} from "@tenant/stores/reviews/review.client";
import {Reducers} from "@lib/helpers/stores/reducers";
import {WhereItem} from "@juulsgaard/store-service";
import {SharedProductStore} from "../../../shared/product/shared-product.store";

@Injectable()
export class ReviewStore extends ScopedStore<ReviewState> implements SharedProductStore {

  reviews$ = this.selectNotNull(x => x.products?.filter(x => !x.approved));
  approvedReviews$ = this.selectNotNull(x => x.products?.filter(x => x.approved));

  constructor(private client: ReviewClient) {
    super({}, inject(TenantScope));
  }

  loadReviews = this.command(this.client)
    .withAction(c => c.loadReviews)
    .isInitial()
    .withReducer(products => ({products}));

  loadReview = this.command(this.client)
    .withAction(c => c.loadReview)
    .cancelConcurrent(x => x)
    .targetList('products')
    .withReducer(Reducers.updateById());

  updateReview = this.command(this.client)
    .withAction(c => c.updateReview)
    .withSuccessMessage('Updated Review')
    .targetList('products')
    .targetItem(WhereItem.IdMatchesPayload(x => x.productId))
    .targetList('reviews')
    .targetItem(() => review => !review.completedAt)
    .withReducer((data, state) => ({state, ...data}));

  approveReview = this.command(this.client)
    .withDeferredAction(c => c.approveReview)
    .withSuccessMessage('Approved Product')
    .targetList('products')
    .targetItem(WhereItem.IdMatchesPayload())
    .withReducer(() => ({approved: true}));

  denyReview = this.command(this.client)
    .withDeferredAction(c => c.denyReview)
    .withSuccessMessage('Product was sent back to Supplier')
    .targetList('products')
    .withReducer(Reducers.deleteById());

  unapproveReview = this.command(this.client)
    .withAction(c => c.unapproveReview)
    .withSuccessMessage('Unapproved Product')
    .targetList('products')
    .withReducer(Reducers.replaceById());


  updateProduct = this.command(this.client)
    .withAction(c => c.updateProduct)
    .withSuccessMessage('Updated Product')
    .targetList('products')
    .withReducer(Reducers.updateById());

  updateComposition = this.command(this.client)
    .withAction(c => c.updateComposition)
    .withSuccessMessage('Updated Composition')
    .targetList('products')
    .withReducer(Reducers.updateById());

  updateInstructions = this.command(this.client)
    .withAction(c => c.updateInstructions)
    .withSuccessMessage('Updated Instructions')
    .targetList('products')
    .withReducer(Reducers.updateById());

  printProduct = this.command(this.client)
    .withDeferredAction(c => c.printProduct)
    .targetList('products')
    .withReducer(Reducers.deleteById());
}

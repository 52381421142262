import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UserAdminModel, UserCreateModel, UserListModel, UserUpdateModel} from "@lib/models/users.models";
import {tap} from "rxjs";
import {UserPasswordUpdateModel} from "@lib/models/generic.models";

@Injectable({providedIn: 'root'})
export class FacilityUserClient {
  constructor(private httpClient: HttpClient) {
  }

  loadUsers() {
    return this.httpClient.get<UserListModel[]>(`facility://facility/users`);
  }

  loadUser(userId: string) {
    return this.httpClient.get<UserAdminModel>(`facility://facility/user/${userId}`)
      .pipe(tap(x => x.loaded = true));
  }

  addUser(data: UserCreateModel) {
    return this.httpClient.post<UserListModel>(`facility://facility/users`, data);
  }

  updateUser({id, ...data}: UserUpdateModel) {
    return this.httpClient.put<UserAdminModel>(`facility://facility/user/${id}`, data);
  }

  requestPassword(userId: string) {
    return this.httpClient.patch<void>(`facility://facility/user/${userId}/new-password`, {});
  }

  updatePassword({userId, ...data}: UserPasswordUpdateModel) {
    return this.httpClient.patch<void>(`facility://facility/user/${userId}/password`, data);
  }

  resetLoginAttempts(userId: string) {
    return this.httpClient.patch<void>(`facility://facility/user/${userId}/login-attempts`, {});
  }

  removeUser(userId: string) {
    return this.httpClient.delete<void>(`facility://facility/user/${userId}`);
  }
}

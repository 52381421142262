import {Injectable} from '@angular/core';
import {AdminScope} from "@lib/scopes/admin.scope";
import {PrintStore} from "@admin/stores/print/print.store";
import {firstValueFrom} from "rxjs";
import {downloadPost} from "@lib/helpers/download";
import {environment} from "../../../env/environment";
import {applyQueryParam} from "@juulsgaard/ts-tools";

@Injectable()
export class PrintService {

  constructor(private adminScope: AdminScope, private printStore: PrintStore) {
  }

  async downloadRequestExport(tenantId?: string, supplierId?: string, templateId?: string) {
    const grouping = await firstValueFrom(this.printStore.grouping$);
    const token = await firstValueFrom(this.adminScope.authToken$);
    const url = tenantId ? `/tenant/${tenantId}` : supplierId ? `/facility/${supplierId}` : '';
    let fullUrl = `${environment.server}prints/export${url}`;
    if (templateId) fullUrl = applyQueryParam(fullUrl, 'templateId', templateId);
    downloadPost(fullUrl, grouping, token);
  }

  async downloadIcons() {
    const token = await firstValueFrom(this.adminScope.authToken$);
    downloadPost(`${environment.server}prints/export/icons`, {}, token);
  }
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  AdminPrintTemplate, PrintTemplateCreateModel, PrintTemplateUpdateModel
} from "@tenant/stores/templates/template.models";

@Injectable({providedIn: 'root'})
export class TemplateClient {
  constructor(private httpClient: HttpClient) {
  }

  //<editor-fold desc="Print Template Load">

  loadPrintTemplates() {
    return this.httpClient.get<AdminPrintTemplate[]>(`tenant://tenant/templates/admin`);
  }

  //</editor-fold>

  //<editor-fold desc="Print Template">

  createTemplate({file}: PrintTemplateCreateModel) {
    const payload = new FormData();
    payload.set('image', file);
    return this.httpClient.post<AdminPrintTemplate>(`tenant://tenant/templates`, payload);
  }

  updateTemplate({id, ...data}: PrintTemplateUpdateModel) {
    return this.httpClient.put<AdminPrintTemplate>(`tenant://tenant/template/${id}`, data);
  }

  setDefault(templateId: string) {
    return this.httpClient.patch<void>(`tenant://tenant/template/${templateId}/default`, {});
  }

  replaceFile(templateId: string) {
    return this.httpClient.post<AdminPrintTemplate>(`tenant://tenant/template/${templateId}/upload`, {});
  }

  deleteTemplate(templateId: string) {
    return this.httpClient.delete<void>(`tenant://tenant/template/${templateId}`);
  }

  //</editor-fold>
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Facility} from "@admin/stores/tenants/tenant.models";

@Injectable({providedIn: 'root'})
export class FacilityScopeClient {

  constructor(private httpClient: HttpClient) {
  }

  loadFacility() {
    return this.httpClient.get<Facility>(`facility://facility`);
  }

}

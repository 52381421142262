import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable, of, OperatorFunction, switchMap} from "rxjs";
import {FacilityScope} from "../scopes/facility.scope";
import {map} from "rxjs/operators";
import {UserScope} from "../scopes/user.scope";
import {TenantScope} from "../scopes/tenant.scope";
import {AdminScope} from "../scopes/admin.scope";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private userScope: UserScope, private adminScope: AdminScope, private tenantScope: TenantScope, private facilityScope: FacilityScope) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.url.startsWith('refresh://')) {
      return of(this.userScope).pipe(map(x => x.refreshToken), setHeader(req, next));
    }

    if (req.url.startsWith('admin://')) {
      return this.adminScope.authToken$.pipe(setHeader(req, next));
    }

    if (req.url.startsWith('tenant://')) {
      return this.tenantScope.authToken$.pipe(setHeader(req, next));
    }

    if (req.url.startsWith('facility://')) {
      return this.facilityScope.authToken$.pipe(setHeader(req, next));
    }

    if (req.url.startsWith('user://')) {
      const scope = this.tenantScope.tenantId ? this.tenantScope : this.facilityScope;
      return scope.authToken$.pipe(setHeader(req, next));
    }

    return next.handle(req);
  }
}

function setHeader(req: HttpRequest<any>, next: HttpHandler): OperatorFunction<string, HttpEvent<any>> {
  return source => source.pipe(
    switchMap(token =>
      next.handle(
        req.clone({setHeaders: {'Authorization': `Bearer ${token}`}})
      )
    )
  );
}

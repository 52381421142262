import {Provider} from "@angular/core";
import {AdminUserStore} from "@admin/stores/admins/admin-user.store";
import {CompositionGroupStore} from "@admin/stores/compositions/composition-group.store";
import {InstructionStore} from "@admin/stores/instructions/instruction.store";
import {LanguageStore} from "@admin/stores/languages/language.store";
import {MaterialStore} from "@admin/stores/materials/material.store";
import {TenantStore} from "@admin/stores/tenants/tenant.store";
import {VariantStore} from "@admin/stores/variants/variant.store";
import {PrintStore} from "@admin/stores/print/print.store";
import {IconSetStore} from "@admin/stores/icon-sets/icon-set.store";
import {AdminDashboardStore} from "@admin/stores/dashboard/admin-dashboard.store";

export const adminStores: Provider[] = [
  AdminUserStore,
  CompositionGroupStore,
  InstructionStore,
  LanguageStore,
  MaterialStore,
  VariantStore,
  TenantStore,
  PrintStore,
  IconSetStore,
  AdminDashboardStore
];

import {Injectable} from '@angular/core';
import {parseJwt} from "@juulsgaard/ts-tools";
import {AuthException} from "../exceptions/auth.exception";
import {BehaviorSubject, distinctUntilChanged, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {AuthClient, LoginModel, PasswordResetModel} from "../clients/auth.client";
import {environment} from "../../env/environment";
import {ApiException} from "@lib/exceptions/api.exception";
import {HttpStatusCode} from "@angular/common/http";
import {Loading} from "@juulsgaard/rxjs-tools";

@Injectable()
export class AuthService {

  private _refreshToken$ = new BehaviorSubject<Token|undefined>(undefined);

  userId$: Observable<string|undefined>;
  isAdmin$: Observable<boolean>;
  isSuperAdmin$: Observable<boolean>;

  get isAdmin() {return this._refreshToken$.value?.admin ?? false}
  get isSuperAdmin() {return this._refreshToken$.value?.superAdmin ?? false}

  get hasRefreshToken() {return !!this._refreshToken$.value}

  get refreshToken(): string {

    const token = this._refreshToken$.value;
    if (!token) {
      throw new AuthException('Refresh token not found');
    }

    if ((new Date().getTime() / 1000) > token.expiry) {
      this.logOut();
      throw new AuthException('Refresh token expired', true);
    }

    return token.token;
  }

  constructor(private client: AuthClient) {
    const token = sessionStorage.getItem('refreshToken') ?? localStorage.getItem('refreshToken');
    if (token) this.setRefreshToken(token);

    this.userId$ = this._refreshToken$.pipe(
      map(x => x?.userId),
      distinctUntilChanged()
    );

    this.isAdmin$ = this._refreshToken$.pipe(
      map(x => x?.admin ?? false),
      distinctUntilChanged()
    );

    this.isSuperAdmin$ = this._refreshToken$.pipe(
      map(x => x?.superAdmin ?? false),
      distinctUntilChanged()
    );
  }

  private setRefreshToken(token: string, remember?: boolean) {

    const data = parseJwt<TokenData>(token);
    const expiry = data.exp - 120;
    if ((new Date().getTime() / 1000) > expiry) {
      console.error('Refresh token is expired');
      return;
    }

    const isAdmin = data.role === Role.Admin || data.role === Role.SuperAdmin;
    const isSuperAdmin = data.role === Role.SuperAdmin;

    if (data.iss !== environment.server) {
      console.error('Refresh token issuer is invalid');
      return;
    }

    if (data.aud !== environment.audience) {
      console.error('Refresh token audience is invalid');
      return;
    }

    if (remember === true) localStorage.setItem('refreshToken', token);
    if (remember === false) {
      localStorage.removeItem('refreshToken');
      sessionStorage.setItem('refreshToken', token);
    }

    this._refreshToken$.next({token, expiry, userId: data.sub, admin: isAdmin, superAdmin: isSuperAdmin});
  }

  private reset() {
    localStorage.removeItem('refreshToken');
    sessionStorage.removeItem('refreshToken');
    this._refreshToken$.next(undefined);
  }

  logOut() {
    this.reset();
    location.href = document.baseURI + '/login';
  }

  logOutIfCritical(error: unknown) {
    if (!(error instanceof ApiException)) return;
    if (error.statusCode !== HttpStatusCode.Unauthorized) return;
    this.logOut();
  }

  logIn(data: LoginModel, remember: boolean) {
    const state = Loading.Async(this.client.logIn(data));
    state.then(x => {
      if (!x.refreshToken) return;
      this.setRefreshToken(x.refreshToken, remember);
    });
    return state;
  }

  resetPassword(data: PasswordResetModel) {
    const state = Loading.Async(this.client.resetPassword(data));
    state.then(x => {
      if (!x.refreshToken) return;
      this.setRefreshToken(x.refreshToken, true);
    });
    return state;
  }
}

interface Token {
  token: string;
  expiry: number;
  userId: string;
  admin: boolean;
  superAdmin: boolean;
}

interface TokenData {
  exp: number;
  sub: string;
  iss: string;
  aud: string;
  role: Role;
}

enum Role {
  Admin = 'Admin',
  SuperAdmin = 'SuperAdmin'
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AdminClaim, ClaimCreateModel, ClaimUpdateModel} from "@tenant/stores/claims/claim.models";

@Injectable({providedIn: 'root'})
export class ClaimClient {
  constructor(private httpClient: HttpClient) {
  }

  //<editor-fold desc="Claim">

  createClaim(data: ClaimCreateModel) {
    return this.httpClient.post<AdminClaim>(`tenant://tenant/claims`, data);
  }

  updateClaim({id, ...data}: ClaimUpdateModel) {
    return this.httpClient.put<AdminClaim>(`tenant://tenant/claim/${id}`, data);
  }

  archiveClaim(claimId: string) {
    return this.httpClient.delete<void>(`tenant://tenant/claim/${claimId}`);
  }

  //</editor-fold>

  //<editor-fold desc="Claim Load">

  loadClaimsAdmin() {
    return this.httpClient.get<AdminClaim[]>(`tenant://tenant/claims/admin`);
  }

  //</editor-fold>
}

import {Injectable} from '@angular/core';
import {IScope} from "./scope.interface";
import {
  BehaviorSubject, distinctUntilChanged, filter, first, mergeWith, Observable, pairwise, switchMap, tap, throttleTime
} from "rxjs";
import {UserScope} from "./user.scope";
import {AuthClient} from "../clients/auth.client";
import {map} from "rxjs/operators";
import {AuthTokenLoader} from "./authTokenLoader";
import {permanentCache} from "@juulsgaard/rxjs-tools";
import {AuthService} from "@lib/services/auth.service";

@Injectable()
export class TenantScope implements IScope {

  reset$: Observable<void>;

  private _tenantId$ = new BehaviorSubject<string | undefined>(undefined);
  tenantId$ = this._tenantId$.asObservable();

  set tenantId(id: string | undefined) {
    this._tenantId$.next(id)
  }

  get tenantId() {
    return this._tenantId$.value
  }

  authToken$: Observable<string>;

  constructor(private userScope: UserScope, private client: AuthClient, private authService: AuthService) {

    // Reset when scope is reset or changed to a new valid scope
    // Also emits when the user scope changes
    this.reset$ = this._tenantId$.pipe(
      pairwise(),
      filter(([oldVal, newVal]) => !!oldVal && oldVal !== newVal),
      map(() => undefined),

      mergeWith(this.userScope.reset$),
      throttleTime(0)
    );


    const token$ = this._tenantId$.pipe(
      distinctUntilChanged(),
      map(id => new AuthTokenLoader(() => this.client.getTenantToken(id))),
      permanentCache()
    );

    // Evaluate token on request
    this.authToken$ = token$.pipe(
      first(),
      switchMap(x => x.getToken$()),
      tap({error: (e: Error) => this.authService.logOutIfCritical(e)})
    );
  }

}

import {Injectable} from '@angular/core';
import {ScopedStore} from "@lib/helpers/stores/scoped-store";
import {
  InstructionIconAltUploadPayload, InstructionIconInfo, InstructionStoreState, InstructionTextInfo
} from "@admin/stores/instructions/instruction.models";
import {AdminScope} from "@lib/scopes/admin.scope";
import {InstructionClient} from "@admin/stores/instructions/instruction.client";
import {Reducers} from "@lib/helpers/stores/reducers";
import {WhereItem} from "@juulsgaard/store-service";
import {Parsers} from "@lib/helpers/stores/parsers";
import {updateListItem} from "@lib/helpers/stores/reducer-utils";
import {map} from "rxjs/operators";
import {isEmptyGuid} from "@lib/helpers/guids";
import {arrToMap} from "@juulsgaard/ts-tools";
import {Observable} from "rxjs";

@Injectable()
export class InstructionStore extends ScopedStore<InstructionStoreState> {

  slots$ = this.selectNotNull(x => x.slots);

  options$: Observable<InstructionTextInfo[]> = this.selector(
    this.slots$.pipe(map(slots => slots
      .filter(x => x.loaded)
      .flatMap(x => x.textOptions!.map(o => ({id: o.id, text: o.text, slotId: x.id})))))
  );
  optionLookup$ = this.selector(
    this.options$.pipe(map(list => arrToMap(list, x => x.id)))
  );

  icons$: Observable<InstructionIconInfo[]> = this.selector(
    this.slots$.pipe(map(slots => slots
      .filter(x => x.loaded && !isEmptyGuid(x.id))
      .flatMap(x => x.icons!.map(i => ({id: i.id, name: i.name, slotId: x.id})))))
  );
  iconLookup$ = this.selector(
    this.icons$.pipe(map(list => arrToMap(list, x => x.id)))
  );

  constructor(scope: AdminScope, private client: InstructionClient) {
    super({}, scope);
  }

  loadSlots = this.command(this.client)
    .withAction(c => c.loadAdminInstructions)
    .isInitial()
    .withReducer((slots) => ({slots}));

  loadSlot = this.command(this.client)
    .withAction(c => c.loadAdminInstruction)
    .cancelConcurrent(x => x)
    .withParser(Parsers.dates(true))
    .targetList('slots')
    .withReducer(Reducers.updateById());

  //<editor-fold desc="Slots">

  createSlot = this.command(this.client)
    .withAction(c => c.createSlot)
    .withSuccessMessage('Created Instruction Slot')
    .targetList('slots')
    .withReducer(Reducers.addition());

  updateSlot = this.command(this.client)
    .withAction(c => c.updateSlot)
    .withSuccessMessage('Updated Instruction Slot')
    .targetList('slots')
    .withReducer(Reducers.updateById());

  moveSlot = this.command(this.client)
    .withDeferredAction(c => c.moveSlot)
    .targetList('slots')
    .withReducer(Reducers.moveById());

  archiveSlot = this.command(this.client)
    .withDeferredAction(c => c.archiveSlot)
    .withSuccessMessage('Archived Instruction Slot')
    .targetList('slots')
    .withReducer(Reducers.archiveWithIndexById());

  //</editor-fold>

  //<editor-fold desc="Text Options">

  loadInstructionText = this.command(this.client)
    .withAction(c => c.loadInstructionText)
    .cancelConcurrent(x => x.id)
    .targetList('slots')
    .targetItem(WhereItem.IdMatchesPayload(x => x.slotId))
    .targetList('textOptions')
    .withReducer(Reducers.updateById());

  createOption = this.command(this.client)
    .withAction(c => c.createOption)
    .withSuccessMessage('Created Instruction Text')
    .targetList('slots')
    .targetItem(WhereItem.IdMatchesPayload(x => x.slotId))
    .targetList('textOptions')
    .withReducer(Reducers.addition());

  updateOption = this.command(this.client)
    .withAction(c => c.updateOption)
    .withSuccessMessage('Updated Instruction Text')
    .targetList('slots')
    .targetItem(WhereItem.IdMatchesPayload(x => x.slotId))
    .targetList('textOptions')
    .withReducer(Reducers.updateById());

  archiveOption = this.command(this.client)
    .withDeferredAction(c => c.archiveOption)
    .withSuccessMessage('Archived Instruction Text')
    .targetList('slots')
    .targetItem(WhereItem.IdMatchesPayload(x => x.slotId))
    .targetList('textOptions')
    .modify(x => x.id)
    .withReducer(Reducers.archiveById());

  deleteOptionAlias = this.command(this.client)
    .withDeferredAction(c => c.deleteOptionAlias)
    .withSuccessMessage('Deleted Alias')
    .targetList('slots')
    .targetItem(WhereItem.IdMatchesPayload(x => x.slotId))
    .targetList('textOptions')
    .targetItem(WhereItem.IdMatchesPayload(x => x.parentId))
    .targetList('aliases')
    .modify(x => x.aliasId)
    .withReducer(Reducers.deleteById());

  //</editor-fold>

  //<editor-fold desc="Icons">

  loadInstructionIcon = this.command(this.client)
    .withAction(c => c.loadAdminInstructionIcon)
    .cancelConcurrent(x => x.id)
    .targetList('slots')
    .targetItem(WhereItem.IdMatchesPayload(x => x.slotId))
    .targetList('icons')
    .withReducer(Reducers.updateById());

  createIcon = this.command(this.client)
    .withAction(c => c.createIcon)
    .withSuccessMessage('Created Instruction Icon')
    .withParser(Parsers.dates(true))
    .targetList('slots')
    .targetItem(WhereItem.IdMatchesPayload(x => x.slotId))
    .targetList('icons')
    .withReducer(Reducers.addition());

  updateIcon = this.command(this.client)
    .withAction(c => c.updateIcon)
    .withSuccessMessage('Updated Instruction Icon')
    .withParser(Parsers.dates(true))
    .targetList('slots')
    .targetItem(WhereItem.IdMatchesPayload(x => x.slotId))
    .targetList('icons')
    .withReducer(Reducers.updateById());

  replaceIcon = this.command(this.client)
    .withAction(c => c.replaceIcon)
    .withSuccessMessage('Icon was replaced')
    .cancelConcurrent(x => x.id)
    .withParser(Parsers.dates(true))
    .targetList('slots')
    .targetItem(WhereItem.IdMatchesPayload(x => x.slotId))
    .targetList('icons')
    .withReducer(Reducers.updateById());

  moveIcon = this.command(this.client)
    .withDeferredAction(c => c.moveIcon)
    .targetList('slots')
    .targetItem(WhereItem.IdMatchesPayload(x => x.slotId))
    .targetList('icons')
    .withReducer(Reducers.moveById());

  archiveIcon = this.command(this.client)
    .withDeferredAction(c => c.archiveIcon)
    .withSuccessMessage('Archived Instruction Icon')
    .targetList('slots')
    .targetItem(WhereItem.IdMatchesPayload(x => x.slotId))
    .targetList('icons')
    .modify(x => x.id)
    .withReducer(Reducers.archiveWithIndexById());
  //</editor-fold>

  setIconAlt = this.command(this.client)
    .withAction(c => c.setIconAlt)
    .withSuccessMessage('Updated Icon Alt')
    .withParser(Parsers.dates(true))
    .targetList('slots')
    .targetItem(WhereItem.IdMatchesPayload(x => x.slotId))
    .targetList('icons')
    .targetItem(WhereItem.IdMatchesPayload(x => x.iconId))
    .targetList('alternativeIcons')
    .withReducer(Reducers.setElement(x => x.setId));

  _setIconAlt = this.command()
    .withPayload<InstructionIconAltUploadPayload>()
    .targetList('slots')
    .targetItem(WhereItem.IdMatchesPayload(x => x.slotId))
    .targetList('icons')
    .targetItem(WhereItem.IdMatchesPayload(x => x.iconId))
    .targetList('alternativeIcons')
    .withReducer(({alt}, state) => updateListItem(state, x => x.setId === alt.setId, alt, true));

  deleteIconAlt = this.command(this.client)
    .withDeferredAction(c => c.deleteIconAlt)
    .withSuccessMessage('Deleted Icon Alt')
    .targetList('slots')
    .targetItem(WhereItem.IdMatchesPayload(x => x.slotId))
    .targetList('icons')
    .targetItem(WhereItem.IdMatchesPayload(x => x.iconId))
    .targetList('alternativeIcons')
    .modify(x => x.id)
    .withReducer(Reducers.deleteElement(x => x.setId));
}

import {ArrayType, KeysOfType, KeysOfTypeOrNull, SimpleObject, WithId} from "@juulsgaard/ts-tools";
import {BaseReducers, ListReducer, ObjectReducer} from "@juulsgaard/store-service";
import {
  archiveListItem, archiveListItemWithIndex, bulkRelocateListItems, moveListItem, relocateListItem, removeListItem,
  removeListItemWithIndex
} from "./reducer-utils";
import {ISorted} from "@lib/models/sorted.interface";
import {IArchived} from "@lib/models/archived.interface";
import {BulkRelocateModel, MoveModel, RelocateModel} from "@lib/models/generic.models";

export class Reducers extends BaseReducers {
  /**
   * Move an element to a new Index
   * Element is targeted based on ID
   * @param getScope - A method to define the scope of the element
   */
  static moveById<TState extends (WithId&ISorted)[], TData extends MoveModel>(getScope?: (x: ArrayType<TState>) => any): ListReducer<TState, TData> {
    return (data, state) => moveListItem(state, x => x.id === data.id, data.index, getScope);
  }

  /**
   * Move an element to a new Scope
   * Element is targeted based on ID
   */
  static relocateById<TState extends (WithId&ISorted)[], TData extends RelocateModel>(scopeKey: KeysOfType<ArrayType<TState>, string>): ListReducer<TState, TData> {
    return (data, state) => relocateListItem(state, x => x.id === data.id, x => ({[scopeKey]: (x as any)[scopeKey]} as Partial<ArrayType<TState>>), {[scopeKey]: data.parentId} as any) as TState
  }

  /**
   * Move multiple elements to a new Scope
   * Elements are targeted based on ID
   */
  static bulkRelocateById<TState extends (WithId&ISorted)[], TData extends BulkRelocateModel>(scopeKey: KeysOfTypeOrNull<ArrayType<TState>, string>): ListReducer<TState, TData> {
    return (data, state) => bulkRelocateListItems(state, x => x.id, data.ids, x => ({[scopeKey]: (x as any)[scopeKey]} as Partial<ArrayType<TState>>), {[scopeKey]: data.parentId} as any) as TState
  }

  /**
   * Archive an element.
   * Element is targeted based on ID
   */
  static archiveById<TState extends (WithId&IArchived)[], TData extends string>(): ListReducer<TState, TData> {
    return (data, state) => archiveListItem(state, x => x.id === data) as TState
  }

  /**
   * Archive an element.
   * Element is targeted based on ID
   */
  static archiveWithIndexById<TState extends (WithId&ISorted&IArchived)[], TData extends string>(getScope?: (x: ArrayType<TState>) => any): ListReducer<TState, TData> {
    return (data, state) => archiveListItemWithIndex(state, x => x.id === data, getScope);
  }

  /**
   * Delete an element with an Index
   * Element is targeted based on ID
   */
  static deleteWithIndexById<TState extends (WithId&ISorted)[], TData extends string>(getScope?: (x: ArrayType<TState>) => any): ListReducer<TState, TData> {
    return (data, state) => removeListItemWithIndex(state, x => x.id === data, getScope) as TState
  }

  /**
   * Mark an item as archived by moving it to a different collection
   * @param activeTarget - Active item list prop
   * @param archivedTarget - Archived item list prop
   */
  static moveArchiveById<TState extends SimpleObject, TData extends string, TElement extends WithId>(
    activeTarget: KeysOfTypeOrNull<TState, TElement[]>,
    archivedTarget: KeysOfTypeOrNull<TState, TElement[]>,
  ): ObjectReducer<TState, TData> {
    return (id, state) => {

      let active = state[activeTarget] as TElement[]|undefined;
      if (!active) return state;

      const index = active.findIndex(x => x.id === id);
      if (index < 0) return state;

      active = [...active];
      const item = active.splice(index, 1)[0]!;

      let archived = state[archivedTarget] as TElement[]|undefined;
      archived = archived ? [...archived] : undefined;

      archived?.push(item);

      return {[activeTarget]: active, [archivedTarget]: archived} as TState;
    }
  }

  /**
   * Move an item from an archived collection to an active one
   * @param activeTarget - Active item list prop
   * @param archivedTarget - Archived item list prop
   */
  static moveRestoreById<TState extends SimpleObject, TData extends WithId>(
    activeTarget: KeysOfTypeOrNull<TState, TData[]>,
    archivedTarget: KeysOfTypeOrNull<TState, TData[]>,
  ): ObjectReducer<TState, TData> {
    return (data, state) => {

      let archived = state[archivedTarget] as TData[]|undefined;
      let active = state[activeTarget] as TData[]|undefined;
      if (!archived && !active) return state;

      if (archived) {
        archived = removeListItem(archived, x => x.id === data.id);
      }

      if (active) {
        active = [...active, data];
      }

      return {[activeTarget]: active, [archivedTarget]: archived} as TState;
    }
  }
}

//<editor-fold desc="List Items">
import {ArrayType, deepEquals, WithId} from "@juulsgaard/ts-tools";
import {IArchived} from "@lib/models/archived.interface";
import {ISorted} from "@lib/models/sorted.interface";

//<editor-fold desc="Update list items">
export function updateListItem<T>(list: T[], selector: (x: T) => boolean, data: T, create: true): T[];
export function updateListItem<T>(list: T[], selector: (x: T) => boolean, data: Partial<T> | ((x: T) => Partial<T>)): T[];
export function updateListItem<T>(
  list: T[],
  selector: (x: T) => boolean,
  data: Partial<T> | T | ((x: T) => Partial<T>),
  create = false,
): T[] {
  list = list ?? [];
  const index = list.findIndex(selector);

  if (index >= 0) {
    const item = list[index]!
    const newData = (data instanceof Function) ? data(item) : data;
    const newList = [...list];
    newList[index] = newData instanceof Object ? {...item, ...newData} : newData;
    return newList;
  }

  if (create && !(data instanceof Function)) {
    return [...list, data as T];
  }

  return list;
}

export function updateListItems<T>(
  list: T[],
  filter: (x: T) => boolean,
  data: Partial<T> | ((x: T) => Partial<T>)
): T[] {
  list = list ?? [];
  return list.map(x => {
    if (!filter(x)) return x;
    const newData = (data instanceof Function) ? data(x) : data;
    return {...x, ...newData};
  });
}

export function updateListItemsById<TItem extends WithId>(
  list: TItem[],
  ids: string[],
  data: Partial<TItem> | ((x: TItem) => Partial<TItem>)
) {
  const idSet = new Set(ids);

  return list.map(x => {
    if (!idSet.has(x.id)) return x;
    const newData = (data instanceof Function) ? data(x) : data;
    return {...x, ...newData};
  });
}
//</editor-fold>

//<editor-fold desc="Remove List Items">
export function removeListItem<T>(list: T[], selector: (x: T) => boolean): T[] {
  const index = list.findIndex(selector);
  if (index < 0) return list;

  const newList = [...list];
  newList.splice(index, 1);
  return newList;
}

export function removeListItemWithIndex<TList extends ISorted[]>(list: TList, selector: (x: ArrayType<TList>) => boolean, identifier?: (x: ArrayType<TList>) => any): TList;
export function removeListItemWithIndex<T extends ISorted>(list: T[], selector: (x: T) => boolean, identifier?: (x: T) => any): T[];
export function removeListItemWithIndex<T extends ISorted>(list: T[], selector: (x: T) => boolean, identifier?: (x: T) => any): T[] {

  const itemIndex = list.findIndex(selector);
  if (itemIndex < 0) return list;

  const item = list[itemIndex]!;
  const result = [...list];

  const oldIndex = item.index;
  const identity = identifier ? identifier(item) : null;

  result.splice(itemIndex, 1);
  result.forEach((x, i) => {
    if (x.index < oldIndex) return;
    if (identifier && identifier(x) !== identity) return;
    result[i] = {...x, index: x.index - 1};
  });

  return result;
}
//</editor-fold>

//<editor-fold desc="Move List Items">
export function moveListItem<TList extends ISorted[]>(
  list: TList,
  selector: (x: ArrayType<TList>) => boolean,
  newIndex: number,
  getScope?: (x: ArrayType<TList>) => any,
): TList;
export function moveListItem<T extends ISorted>(
  list: T[],
  selector: (x: T) => boolean,
  newIndex: number,
  getScope?: (x: T) => any,
) : T[];
export function moveListItem<T extends ISorted>(
  list: T[],
  selector: (x: T) => boolean,
  newIndex: number,
  getScope?: (x: T) => any,
) : T[] {

  let itemIndex = list.findIndex(selector);
  if (itemIndex < 0) return list;
  let item = list[itemIndex]!;

  const oldIndex = item.index;

  let affectedItems: T[];
  let result: T[];

  if (getScope) {
    const scope = getScope(item);
    const selector: (x: any) => boolean = Array.isArray(scope) ? x => deepEquals(getScope(x), scope) : x => getScope(x) === scope;

    affectedItems = [];
    result = list.map(x => {
      if (!selector(x)) return x;
      const item = {...x};
      affectedItems.push(item);
      return item;
    });

  } else {
    result = list.map(x => ({...x}));
    affectedItems = result;
  }

  item = result[itemIndex]!;

  affectedItems
    .filter(i => i.index >= newIndex)
    .forEach(i => i.index++);

  item.index = newIndex;

  affectedItems
    .filter(i => i.index > oldIndex)
    .forEach(i => i.index--);

  return result;
}
//</editor-fold>

//<editor-fold desc="Relocate list items">
export function relocateListItem<T extends ISorted, TParent extends Partial<T>>(
  list: T[],
  selector: (x: T) => boolean,
  getParent: (x: T) => TParent,
  newParent: TParent,
) {
  let newIndex = list
    .filter(x => deepEquals(getParent(x), newParent))
    .reduce((max, val) => Math.max(max, val.index), -1) + 1;

  return relocateListItemInternal(list, selector, getParent, newParent, newIndex);
}

export function bulkRelocateListItems<T extends ISorted, TId, TParent extends Partial<T>>(
  list: T[],
  selector: (x: T) => TId,
  ids: TId[],
  getParent: (x: T) => TParent,
  newParent: TParent,
) {
  let newIndex = list
    .filter(x => deepEquals(getParent(x), newParent))
    .reduce((max, val) => Math.max(max, val.index), -1) + 1;

  for (let id of ids) {
    const newList = relocateListItemInternal(list, x => selector(x) == id, getParent, newParent, newIndex);
    if (newList !== list) {
      list = newList;
      newIndex++;
    }
  }

  return list;
}

function relocateListItemInternal<T extends ISorted, TParent extends Partial<T>>(
  list: T[],
  selector: (x: T) => boolean,
  getParent: (x: T) => TParent,
  newParent: TParent,
  newIndex: number
) {
  const index = list.findIndex(x => selector(x));
  if (index < 0) return list;

  const item = list[index]!;

  const oldIndex = item.index;
  const oldParent = getParent(item);

  if (deepEquals(oldParent, newParent)) return list;
  const result = [...list];

  result[index] = {...item, ...newParent, index: newIndex};

  result.forEach((val, i) => {
    if (val.index <= oldIndex) return;
    const parent = getParent(val);
    if (!deepEquals(oldParent, parent)) return
    result[i] = {...val, index: val.index - 1};
  });

  return result;
}
//</editor-fold>

export function archiveListItem<T extends IArchived>(list: T[], selector: (x: T) => boolean) {
  return updateListItem(list, selector, {archivedAt: new Date()} as Partial<T>);
}

export function archiveListItemWithIndex<TList extends (ISorted & IArchived)[]>(list: TList, selector: (x: ArrayType<TList>) => boolean, identifier?: (x: ArrayType<TList>) => any): TList;
export function archiveListItemWithIndex<T extends ISorted & IArchived>(list: T[], selector: (x: T) => boolean, identifier?: (x: T) => any): T[];
export function archiveListItemWithIndex<T extends ISorted & IArchived>(list: T[], selector: (x: T) => boolean, identifier?: (x: T) => any): T[] {
  const itemIndex = list.findIndex(selector);
  if (itemIndex < 0) return list;

  const item = list[itemIndex]!;
  const result = [...list];

  const oldIndex = item.index;
  const identity = identifier ? identifier(item) : null;

  result[itemIndex] = {...item, index: -1, archivedAt: new Date()}

  result.forEach((x, i) => {
    if (x.index < oldIndex) return;
    if (identifier && identifier(x) !== identity) return;
    result[i] = {...x, index: x.index - 1};
  });

  return result;
}

//</editor-fold>

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  AdminTag, AdminTagScope, TagAdminLoadModel, TagCreateModel, TagScopeCreateModel, TagScopeUpdateModel, TagUpdateModel
} from "@tenant/stores/tags/tag.models";

@Injectable({providedIn: 'root'})
export class TagClient {

  constructor(private httpClient: HttpClient) {
  }

  //<editor-fold desc="Tag Load">

  loadAdminTags() {
    return this.httpClient.get<TagAdminLoadModel>(`tenant://tenant/tags/admin`);
  }

  //</editor-fold>

  //<editor-fold desc="Tag Scope">

  createScope(data: TagScopeCreateModel) {
    return this.httpClient.post<AdminTagScope>(`tenant://tenant/tag-scopes`, data);
  }

  updateScope({id, ...data}: TagScopeUpdateModel) {
    return this.httpClient.put<AdminTagScope>(`tenant://tenant/tags-scope/${id}`, data);
  }

  deleteScope(scopeId: string) {
    return this.httpClient.delete<void>(`tenant://tenant/tags-scope/${scopeId}`);
  }

  //</editor-fold>

  //<editor-fold desc="Tag">

  createTag({scopeId, ...data}: TagCreateModel) {
    return this.httpClient.post<AdminTag>(`tenant://tenant/tag-scope/${scopeId}/tags`, data);
  }

  updateTag({id, ...data}: TagUpdateModel) {
    return this.httpClient.put<AdminTag>(`tenant://tenant/tag/${id}`, data);
  }

  deleteTag(tagId: string) {
    return this.httpClient.delete<void>(`tenant://tenant/tag/${tagId}`);
  }

  //</editor-fold>
}

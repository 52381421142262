import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AggregateProductStatsModel} from "@admin/stores/dashboard/admin-dashboard.models";

@Injectable({providedIn: 'root'})
export class AdminDashboardClient {
  constructor(private httpClient: HttpClient) {
  }

  loadAdminProductStats() {
    return this.httpClient.get<AggregateProductStatsModel>(`admin://dashboard/admin/products`);
  }
}

import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {MoveModel} from "@lib/models/generic.models";
import {
  CompositionGroupAdminLoadModel, CompositionGroupCreateModel, CompositionGroupListModel, CompositionGroupResponseModel,
  CompositionGroupUpdateModel
} from "@admin/stores/compositions/composition-group.models";
import {AliasTargetModel} from "@lib/models/alias.models";
import {tap} from "rxjs";

@Injectable({providedIn: 'root'})
export class CompositionGroupClient {

  constructor(private httpClient: HttpClient) {
  }

  //<editor-fold desc="Composition Load">

  loadGroups() {
    return this.httpClient.get<CompositionGroupListModel[]>(`admin://compositions/admin`);
  }

  loadGroup(groupId: string) {
    return this.httpClient.get<CompositionGroupAdminLoadModel>(`admin://composition/${groupId}/admin`)
      .pipe(tap(x => x.loaded = true));
  }

  //</editor-fold>

  //<editor-fold desc="Composition">

  createGroup(data: CompositionGroupCreateModel) {
    return this.httpClient.post<CompositionGroupListModel>(`admin://material/groups`, data);
  }

  updateGroup({id, ...data}: CompositionGroupUpdateModel) {
    return this.httpClient.put<CompositionGroupResponseModel>(`admin://material/group/${id}`, data);
  }

  moveGroup({id, ...data}: MoveModel) {
    return this.httpClient.patch<void>(`admin://material/group/${id}/move`, data);
  }

  deleteGroup(groupId: string) {
    return this.httpClient.delete<void>(`admin://material/group/${groupId}`);
  }

  deleteAlias({parentId, aliasId}: AliasTargetModel) {
    return this.httpClient.delete<void>(`admin://material/group/${parentId}/alias/${aliasId}`);
  }

  //</editor-fold>
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  AdminInstructionIconAlt, InstructionAliasTargetModel, InstructionIconAdminLoadModel, InstructionIconAltUploadModel,
  InstructionIconCreateModel, InstructionIconListModel, InstructionIconResponseModel, InstructionIconTargetModel,
  InstructionIconUpdateModel, InstructionIconUploadModel, InstructionMoveModel, InstructionSlotCreateModel,
  InstructionSlotListModel, InstructionSlotLoadModel, InstructionSlotUpdateModel, InstructionTargetModel,
  InstructionTextAdminLoadModel, InstructionTextCreateModel, InstructionTextListModel, InstructionTextResponseModel,
  InstructionTextUpdateModel
} from "@admin/stores/instructions/instruction.models";
import {MoveModel} from "@lib/models/generic.models";
import {tap} from "rxjs";

@Injectable({providedIn: 'root'})
export class InstructionClient {
  constructor(private httpClient: HttpClient) {
  }

  //<editor-fold desc="Instruction Load">

  loadAdminInstructions() {
    return this.httpClient.get<InstructionSlotListModel[]>(`admin://instructions/admin`);
  }

  loadAdminInstruction(slotId: string) {
    return this.httpClient.get<InstructionSlotLoadModel>(`admin://instruction/${slotId}/admin`)
      .pipe(tap(x => x.loaded = true));
  }

  //</editor-fold>

  //<editor-fold desc="Instruction Icon">

  loadAdminInstructionIcon({id}: InstructionTargetModel) {
    return this.httpClient.get<InstructionIconAdminLoadModel>(`admin://instruction/icon/${id}/admin`)
      .pipe(tap(x => x.loaded = true));
  }

  createIcon({slotId, image}: InstructionIconCreateModel) {
    const data = new FormData();
    data.append('file', image);
    return this.httpClient.post<InstructionIconListModel>(`admin://instruction/${slotId}/icons`, data);
  }

  updateIcon({id, ...data}: InstructionIconUpdateModel) {
    return this.httpClient.put<InstructionIconResponseModel>(`admin://instruction/icon/${id}`, data);
  }

  replaceIcon({id, image}: InstructionIconUploadModel) {
    const data = new FormData();
    data.append('file', image);
    return this.httpClient.post<InstructionIconResponseModel>(`admin://instruction/icon/${id}/replace`, data);
  }

  moveIcon({id, slotId, ...data}: InstructionMoveModel) {
    return this.httpClient.patch<void>(`admin://instruction/icon/${id}/move`, data);
  }

  archiveIcon({id}: InstructionTargetModel) {
    return this.httpClient.delete<void>(`admin://instruction/icon/${id}`);
  }

  //</editor-fold>

  setIconAlt({iconId, iconSetId, image}: InstructionIconAltUploadModel) {
    const payload = new FormData();
    payload.set('file', image);
    return this.httpClient.post<AdminInstructionIconAlt>(`admin://instruction/icon/${iconId}/alt/${iconSetId}`, payload);
  }

  deleteIconAlt({iconId, id}: InstructionIconTargetModel) {
    return this.httpClient.delete<void>(`admin://instruction/icon/${iconId}/alt/${id}`);
  }

  //<editor-fold desc="Instruction Slot">

  createSlot(data: InstructionSlotCreateModel) {
    return this.httpClient.post<InstructionSlotListModel>(`admin://instructions`, data);
  }

  updateSlot({id, ...data}: InstructionSlotUpdateModel) {
    return this.httpClient.put<InstructionSlotListModel>(`admin://instructions/${id}`, data);
  }

  moveSlot({id, ...data}: MoveModel) {
    return this.httpClient.patch<void>(`admin://instructions/${id}/move`, data);
  }

  archiveSlot(slotId: string) {
    return this.httpClient.delete<void>(`admin://instructions/${slotId}`);
  }

  //</editor-fold>

  //<editor-fold desc="Instruction Text">

  loadInstructionText({id}: InstructionTargetModel) {
    return this.httpClient.get<InstructionTextAdminLoadModel>(`admin://instruction/text/${id}/admin`)
      .pipe(tap(x => x.loaded = true));
  }

  createOption({slotId, ...data}: InstructionTextCreateModel) {
    return this.httpClient.post<InstructionTextListModel>(`admin://instruction/${slotId}/options`, data);
  }

  updateOption({id, ...data}: InstructionTextUpdateModel) {
    return this.httpClient.put<InstructionTextResponseModel>(`admin://instruction/option/${id}`, data);
  }

  archiveOption({id}: InstructionTargetModel) {
    return this.httpClient.delete<void>(`admin://instruction/option/${id}`);
  }

  deleteOptionAlias({aliasId, parentId}: InstructionAliasTargetModel) {
    return this.httpClient.delete<void>(`admin://instruction/option/${parentId}/alias/${aliasId}`);
  }

  //</editor-fold>
}

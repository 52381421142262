import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  AdminCountry, AdminLanguage, CountryCreateModel, CountryUpdateModel, LanguageCreateModel, LanguagesAdminLoadModel,
  LanguageUpdateModel
} from "@admin/stores/languages/language.models";
import {MoveModel} from "@lib/models/generic.models";

@Injectable({providedIn: 'root'})
export class LanguageClient {
  constructor(private httpClient: HttpClient) {
  }

  //<editor-fold desc="Country">

  createCountry(data: CountryCreateModel) {
    return this.httpClient.post<AdminCountry>(`admin://countries`, data);
  }

  updateCountry({id, ...data}: CountryUpdateModel) {
    return this.httpClient.put<AdminCountry>(`admin://country/${id}`, data);
  }

  deleteCountry(countryId: string) {
    return this.httpClient.delete<void>(`admin://country/${countryId}`);
  }

  //</editor-fold>

  //<editor-fold desc="Language">

  createLanguage(data: LanguageCreateModel) {
    return this.httpClient.post<AdminLanguage>(`admin://languages`, data);
  }

  updateLanguage({id, ...data}: LanguageUpdateModel) {
    return this.httpClient.put<AdminLanguage>(`admin://language/${id}`, data);
  }

  moveLanguage({id, ...data}: MoveModel) {
    return this.httpClient.patch<void>(`admin://language/${id}/move`, data);
  }

  deleteLanguage(languageId: string) {
    return this.httpClient.delete<void>(`admin://language/${languageId}`);
  }

  //</editor-fold>

  //<editor-fold desc="Language Load">

  loadAdminLanguages() {
    return this.httpClient.get<LanguagesAdminLoadModel>(`admin://languages/admin`);
  }

  //</editor-fold>
}

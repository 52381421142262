import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot} from "@angular/router";
import {FacilityScope} from "../scopes/facility.scope";
import {firstValueFrom} from "rxjs";
import {ApiException} from "@lib/exceptions/api.exception";
import {HttpStatusCode} from "@angular/common/http";

@Injectable({providedIn: 'root'})
export class FacilityGuard implements CanActivate, CanDeactivate<any> {

  constructor(private scope: FacilityScope) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    this.scope.facilityId = route.params['facilityId'];
    console.debug('User entered Facility scope', this.scope.facilityId);
    return true;
  }

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ) {
    this.scope.facilityId = undefined;
    console.debug('User left Facility scope')
    return true;
  }
}

@Injectable({providedIn: 'root'})
export class FacilityAccessGuard implements CanActivate {

  constructor(private scope: FacilityScope, private router: Router) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    try {
      await firstValueFrom(this.scope.authToken$);
    } catch (e) {
      if (e instanceof ApiException) {
        if (e.statusCode === HttpStatusCode.Forbidden) {
          console.debug('User does not have access to Facility scope', '- Redirecting to Root');
          return this.router.parseUrl('/');
        }
      }

      console.debug('Could not generate token', '- Redirecting to Error Page');
      await this.router.navigate(['error'], {queryParams: {error: 'FailedToken'}, skipLocationChange: true});
      return false;
    }

    console.debug('User has access to Facility scope', '- Sending through');
    return true;
  }
}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from "../services/auth.service";

@Injectable({providedIn: 'root'})
export class LoggedOutGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.hasRefreshToken) {
      console.debug('User is logged in', '- Sending to root page');
      return this.router.parseUrl('/');
    }

    console.debug('User is not logged in', '- Sending through');
    return true;
  }
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthException} from "../exceptions/auth.exception";

@Injectable({providedIn: 'root'})
export class AuthClient {

  constructor(private httpClient: HttpClient) {
  }

  logIn(data: LoginModel) {
    return this.httpClient.post<AuthResponse>(`api://auth/login`, data);
  }

  resetPassword({token, ...data}: PasswordResetModel) {
    return this.httpClient.post<AuthResponse>(`api://auth/password/reset`, data, AuthClient.getAuthHeader(token));
  }

  private static getAuthHeader(token: string) {
    return {headers: {'Authorization': `Bearer ${token}`}};
  }

  getFacilityToken(id: string|undefined) {
    if (!id) throw new AuthException(`Can't use Facility token outside facility scope`);
    return this.httpClient.post<TokenResponse>(`refresh://auth/token/facility/${id}`, {});
  }

  getTenantToken(id: string|undefined) {
    if (!id) throw new AuthException(`Can't use Tenant token outside tenant scope`);
    return this.httpClient.post<TokenResponse>(`refresh://auth/token/tenant/${id}`, {});
  }

  getAdminToken() {
    return this.httpClient.post<TokenResponse>(`refresh://auth/token/admin`, {});
  }
}

export interface LoginModel {
  username: string;
  password: string;
}

export enum AuthAction {
  ChangePassword = 'ChangePassword'
}

export interface AuthResponse {
  tempToken?: string;
  tempAction?: AuthAction;
  refreshToken?: string;
}

export interface TokenResponse {
  token: string;
}

export interface PasswordResetModel {
  password: string;
  token: string;
}

import {ScopedStore} from "@lib/helpers/stores/scoped-store";
import {FacilityListModel, FacilityResponseModel, TenantStoreState} from "./tenant.models";
import {AdminScope} from "@lib/scopes/admin.scope";
import {TenantClient} from "./tenant.client";
import {Injectable} from "@angular/core";
import {Reducers} from "@lib/helpers/stores/reducers";
import {FacilityClient} from "@tenant/stores/facilities/facility.client";
import {WhereItem} from "@juulsgaard/store-service";
import {AliasTargetModel} from "@lib/models/alias.models";
import {removeListItem} from "@lib/helpers/stores/reducer-utils";
import {map} from "rxjs/operators";
import {arrToMap} from "@juulsgaard/ts-tools";

@Injectable()
export class TenantStore extends ScopedStore<TenantStoreState> {

  tenants$ = this.selectNotNull(x => x.tenants);
  tenantLookup$ = this.selector(
    this.tenants$.pipe(map(tenants => arrToMap(tenants, x => x.id)))
  );

  archivedTenants$ = this.selectNotNull(x => x.archivedTenants);


  facilities$ = this.selectNotNull(x => x.facilities);
  facilityLookup$ = this.selector(
    this.facilities$.pipe(map(facilities => arrToMap(facilities, x => x.id)))
  );

  archivedFacilities$ = this.selectNotNull(x => x.archivedFacilities);

  constructor(scope: AdminScope, private client: TenantClient, private facilityClient: FacilityClient) {
    super({}, scope);
  }

  loadTenants = this.command(this.client)
    .withAction(c => c.loadTenants)
    .isInitial()
    .withReducer(x => x);

  loadArchivedTenants = this.command(this.client)
    .withAction(c => c.loadArchivedTenants)
    .isInitial()
    .withReducer(({tenants, facilities}) => ({archivedTenants: tenants, archivedFacilities: facilities}));

  loadFacility = this.command(this.facilityClient)
    .withAction(c => c.loadFacility)
    .cancelConcurrent(x => x)
    .targetList('facilities')
    .withReducer(Reducers.updateById());

  //<editor-fold desc="Tenants">

  createTenant = this.command(this.client)
    .withAction(c => c.createTenant)
    .withSuccessMessage('Created Client')
    .targetList('tenants')
    .withReducer(Reducers.addition());

  updateTenant = this.command(this.client)
    .withAction(c => c.updateTenant)
    .withSuccessMessage('Updated Client')
    .targetList('tenants')
    .withReducer(Reducers.updateById());

  archiveTenant = this.command(this.client)
    .withDeferredAction(c => c.archiveTenant)
    .withSuccessMessage('Archived Client and Factories')
    .withReducer((tenantId, state) => {
      if (!state.tenants || !state.facilities) return state;

      let {tenants, archivedTenants, facilities, archivedFacilities} = state;

      const tenantIndex = tenants.findIndex(x => x.id == tenantId);
      if (tenantIndex < 0) return state;

      tenants = [...tenants];
      facilities = [...facilities];
      archivedTenants = archivedTenants ? [...archivedTenants] : undefined;
      archivedFacilities = archivedFacilities ? [...archivedFacilities] : undefined;

      const tenant = tenants.splice(tenantIndex, 1)[0]!;
      archivedTenants?.push(tenant);

      for (let i = facilities.length - 1; i >= 0; i--) {
        const facility = facilities[i]!;
        if (facility.tenantId !== tenantId) continue;
        archivedFacilities?.push(facility);
        facilities.splice(i, 1);
      }

      return {tenants, facilities, archivedTenants, archivedFacilities};
    });

  restoreTenant = this.command(this.client)
    .withAction(c => c.restoreTenant)
    .withSuccessMessage('Restored Client')
    .withReducer(Reducers.moveRestoreById('tenants', 'archivedTenants'));

  //</editor-fold>

  //<editor-fold desc="Facilities">

  createFacility = this.command(this.facilityClient)
    .withAction(c => c.createFacility)
    .withSuccessMessage('Created Factory')
    .targetList('facilities')
    .withReducer(Reducers.addition());

  updateFacility = this.command(this.facilityClient)
    .withAction(c => c.updateFacility)
    .withSuccessMessage('Updated Factory')
    .targetList('facilities')
    .withReducer(Reducers.updateById());

  archiveFacility = this.command(this.facilityClient)
    .withDeferredAction(c => c.archiveFacility)
    .withSuccessMessage('Archived Factory')
    .withReducer(Reducers.moveArchiveById('facilities', 'archivedFacilities'));

  restoreFacility = this.command(this.facilityClient)
    .withAction(c => c.restoreFacility)
    .withSuccessMessage('Restored Factory')
    .withReducer(Reducers.moveRestoreById('facilities', 'archivedFacilities'));

  deleteFacilityAlias = this.command(this.facilityClient)
    .withDeferredAction(c => c.deleteAlias)
    .withSuccessMessage('Deleted Alias')
    .targetList('facilities')
    .targetItem(WhereItem.IdMatchesPayload(x => x.parentId))
    .targetList('aliases')
    .modify(x => x.aliasId)
    .withReducer(Reducers.deleteById());

  //</editor-fold>

  _createFacility = this.command()
    .withPayload<FacilityListModel>()
    .targetList('facilities')
    .withReducer(Reducers.addition());

  _updateFacility = this.command()
    .withPayload<FacilityResponseModel>()
    .targetList('facilities')
    .withReducer(Reducers.updateById());

  _archiveFacility = this.command()
    .withPayload<string>()
    .withReducer(Reducers.moveArchiveById('facilities', 'archivedFacilities'));

  _restoreFacility = this.command()
    .withPayload<FacilityListModel>()
    .withReducer(Reducers.moveRestoreById('facilities', 'archivedFacilities'));

  _deleteFacilityAlias = this.command()
    .withPayload<AliasTargetModel>()
    .targetList('facilities')
    .targetItem(WhereItem.IdMatchesPayload(x => x.parentId))
    .targetList('aliases')
    .withReducer((data, state) => removeListItem(state, x => x.id == data.aliasId));
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {FacilityProductStatsModel} from "@facility/stores/dashboard/facility-dashboard.models";

@Injectable({providedIn: 'root'})
export class FacilityDashboardClient {
  constructor(private httpClient: HttpClient) {
  }

  loadFacilityProductStats() {
    return this.httpClient.get<FacilityProductStatsModel>(`facility://dashboard/facility/products`);
  }
}

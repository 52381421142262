import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot} from "@angular/router";
import {firstValueFrom} from "rxjs";
import {TenantScope} from "../scopes/tenant.scope";
import {ApiException} from "@lib/exceptions/api.exception";
import {HttpStatusCode} from "@angular/common/http";

@Injectable({providedIn: 'root'})
export class TenantGuard implements CanActivate, CanDeactivate<any> {

  constructor(private scope: TenantScope, private router: Router) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    this.scope.tenantId = route.params['tenantId'];
    console.debug('User entered Tenant scope', this.scope.tenantId);
    return true;
  }

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ) {
    this.scope.tenantId = undefined;
    console.debug('User left Tenant scope')
    return true;
  }
}

@Injectable({providedIn: 'root'})
export class TenantAccessGuard implements CanActivate {

  constructor(private scope: TenantScope, private router: Router) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    try {
      await firstValueFrom(this.scope.authToken$);
    } catch (e) {
      if (e instanceof ApiException) {
        if (e.statusCode === HttpStatusCode.Forbidden) {
          console.debug('User does not have access to Tenant scope', '- Redirecting to Root');
          return this.router.parseUrl('/');
        }
      }

      console.debug('Could not generate token', '- Redirecting to Error Page');
      await this.router.navigate(['error'], {queryParams: {error: 'FailedToken'}, skipLocationChange: true});
      return false;
    }

    console.debug('User has access to Tenant scope', '- Sending through');
    return true;
  }
}

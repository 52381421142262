import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  Claim, Color, Country, IconSet, InstructionsLoadModel, Language, MaterialsLoadModel, PrintTemplate, TagLoadModel
} from "@tenant/stores/context/tenant-context.models";

@Injectable({providedIn: 'root'})
export class TenantContextClient {
  constructor(private httpClient: HttpClient) {
  }

  loadLanguages() {
    return this.httpClient.get<Language[]>(`user://tenant/languages`);
  }

  loadCountries() {
    return this.httpClient.get<Country[]>(`user://tenant/countries`);
  }

  loadInstructions() {
    return this.httpClient.get<InstructionsLoadModel>(`user://tenant/instructions`);
  }

  loadMaterials() {
    return this.httpClient.get<MaterialsLoadModel>(`user://tenant/materials`);
  }

  loadTags() {
    return this.httpClient.get<TagLoadModel>(`user://tenant/tags`);
  }

  loadColors() {
    return this.httpClient.get<Color[]>(`user://tenant/colors`);
  }

  loadClaims() {
    return this.httpClient.get<Claim[]>(`user://tenant/claims`);
  }

  loadIconSets() {
    return this.httpClient.get<IconSet[]>(`user://icon-sets`);
  }

  loadTemplates() {
    return this.httpClient.get<PrintTemplate[]>(`user://tenant/templates`);
  }

}

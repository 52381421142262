import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  VariantCreateModel, VariantListModel, VariantLoadModel, VariantResponseModel, VariantUpdateModel
} from "@admin/stores/variants/variant.models";
import {tap} from "rxjs";
import {AliasTargetModel} from "@lib/models/alias.models";

@Injectable({providedIn: 'root'})
export class VariantClient {
  constructor(private httpClient: HttpClient) {
  }

  loadAdminVariants() {
    return this.httpClient.get<VariantListModel[]>(`admin://variants/admin`);
  }

  loadAdminVariant(variantId: string) {
    return this.httpClient.get<VariantLoadModel>(`admin://variant/${variantId}/admin`)
      .pipe(tap(x => x.loaded = true));
  }

  createVariant(data: VariantCreateModel) {
    return this.httpClient.post<VariantListModel>(`admin://variants`, data);
  }

  updateVariant({id, ...data}: VariantUpdateModel) {
    return this.httpClient.put<VariantResponseModel>(`admin://variant/${id}`, data);
  }

  archiveVariant(variantId: string) {
    return this.httpClient.delete<void>(`admin://variant/${variantId}`);
  }

  deleteAlias({parentId, aliasId}: AliasTargetModel) {
    return this.httpClient.delete<void>(`admin://variant/${parentId}/alias/${aliasId}`);
  }
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AdminTenant, TenantCreateModel, TenantsLoadModel, TenantUpdateModel} from "./tenant.models";

@Injectable({providedIn: 'root'})
export class TenantClient {

  constructor(private httpClient: HttpClient) {
  }

  loadTenants() {
    return this.httpClient.get<TenantsLoadModel>(`admin://tenants`);
  }

  loadArchivedTenants() {
    return this.httpClient.get<TenantsLoadModel>(`admin://tenants/archived`);
  }

  createTenant(data: TenantCreateModel) {
    return this.httpClient.post<AdminTenant>(`admin://tenants`, data);
  }

  updateTenant({id, ...data}: TenantUpdateModel) {
    return this.httpClient.put<AdminTenant>(`admin://tenant/${id}`, data);
  }

  archiveTenant(tenantId: string) {
    return this.httpClient.delete<void>(`admin://tenant/${tenantId}`);
  }

  restoreTenant(tenantId: string) {
    return this.httpClient.post<AdminTenant>(`admin://tenant/${tenantId}/restore`, {});
  }
}

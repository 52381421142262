import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {firstValueFrom} from "rxjs";
import {AdminScope} from "../scopes/admin.scope";
import {UserScope} from "../scopes/user.scope";
import {ApiException} from "@lib/exceptions/api.exception";
import {HttpStatusCode} from "@angular/common/http";

@Injectable({providedIn: 'root'})
export class AdminGuard implements CanActivate {

  constructor(private scope: UserScope, private router: Router) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (!this.scope.isAdmin) {
      console.debug('User is not an Admin', '- Redirecting to Root');
      return this.router.parseUrl('/');
    }

    console.debug('User is Admin', '- Sending through');
    return true;
  }
}

@Injectable({providedIn: 'root'})
export class AdminAccessGuard implements CanActivate {

  constructor(private scope: AdminScope, private router: Router) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    try {
      await firstValueFrom(this.scope.authToken$);
    } catch (e) {
      if (e instanceof ApiException) {
        if (e.statusCode === HttpStatusCode.Forbidden) {
          console.debug('User does not have access to Admin scope', '- Redirecting to Root');
          return this.router.parseUrl('/');
        }
      }

      console.debug('Could not generate token', '- Redirecting to Error Page');
      await this.router.navigate(['error'], {queryParams: {error: 'FailedToken'}, skipLocationChange: true});
      return false;
    }

    console.debug('User has access to Admin scope', '- Sending through');
    return true;
  }
}


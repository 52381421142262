import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../env/environment";

@Injectable()
export class UrlInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const match = req.url.match(/^(\w+):\/\//);
    if (!match) return next.handle(req);

    const schema = match[1];
    if (schema && !schemas.includes(schema)) return next.handle(req);

    return next.handle(req.clone({url: req.url.replace(`${schema}://`, environment.server)}))
  }
}

const schemas = ['api', 'facility', 'refresh', 'tenant', 'admin', 'user'];

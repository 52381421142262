import {Routes} from "@angular/router";
import {LoggedOutGuard} from "@lib/guards/logged-out.guard";
import {LoggedInGuard} from "@lib/guards/logged-in.guard";
import {ErrorPageComponent} from "@lib/components/pages/error-page/error-page.component";
import {PageNotFoundComponent} from "@lib/components/pages/page-not-found/page-not-found.component";
import {FacilityAccessGuard, FacilityGuard} from "@lib/guards/facility.guard";
import {AdminAccessGuard, AdminGuard} from "@lib/guards/admin.guard";
import {TenantAccessGuard, TenantGuard} from "@lib/guards/tenant.guard";
import {adminStores} from "@admin/stores/admin-stores";
import {tenantStores} from "@tenant/stores/tenant-stores";
import {facilityStores} from "@facility/stores/facility-stores";
import {rootUserScope} from "@lib/scopes/root-scope";
import {TenantScope} from "@lib/scopes/tenant.scope";
import {FacilityScope} from "@lib/scopes/facility.scope";
import {TenantContextStore} from "@tenant/stores/context/tenant-context.store";
import {PrintService} from "@admin/services/print.service";

export const appRoutes: Routes = [
  {
    path: 'login',
    canActivate: [LoggedOutGuard],
    loadChildren: () => import('./login/login.module').then(x => x.LoginModule)
  },
  {
    path: '',
    canActivate: [LoggedInGuard],
    children: [
      {
        path: '',
        data: {root: true},
        loadChildren: () => import('./root/root.module').then(x => x.RootModule)
      },
      {
        path: 'admin',
        data: {admin: true},
        canActivate: [AdminGuard],
        providers: [...adminStores, PrintService],
        children: [
          {
            path: '',
            canActivate: [AdminAccessGuard],
            loadChildren: () => import('./admin/admin.module').then(x => x.AdminModule)
          },
          {
            path: 'tenant/:tenantId',
            canActivate: [TenantGuard],
            canDeactivate: [TenantGuard],
            providers: [...tenantStores, {provide: rootUserScope, useExisting: TenantScope}, TenantContextStore],
            children: [
              {
                path: '',
                canActivate: [TenantAccessGuard],
                loadChildren: () => import('./tenant/tenant.module').then(x => x.TenantModule)
              },
              {
                path: 'facility/:facilityId',
                canActivate: [FacilityGuard, FacilityAccessGuard],
                canDeactivate: [FacilityGuard],
                providers: [...facilityStores],
                loadChildren: () => import('./facility/facility.module').then(x => x.FacilityModule)
              }
            ]
          }
        ]
      },
      {
        path: 'tenant/:tenantId',
        canActivate: [TenantGuard],
        canDeactivate: [TenantGuard],
        providers: [...tenantStores, {provide: rootUserScope, useExisting: TenantScope}, TenantContextStore],
        children: [
          {
            path: '',
            canActivate: [TenantAccessGuard],
            loadChildren: () => import('./tenant/tenant.module').then(x => x.TenantModule)
          },
          {
            path: 'facility/:facilityId',
            canActivate: [FacilityGuard, FacilityAccessGuard],
            canDeactivate: [FacilityGuard],
            providers: [...facilityStores],
            loadChildren: () => import('./facility/facility.module').then(x => x.FacilityModule)
          }
        ]
      },
      {
        path: 'facility/:facilityId',
        canActivate: [FacilityGuard, FacilityAccessGuard],
        canDeactivate: [FacilityGuard],
        providers: [...facilityStores, {provide: rootUserScope, useExisting: FacilityScope}, TenantContextStore],
        loadChildren: () => import('./facility/facility.module').then(x => x.FacilityModule)
      }
    ]
  },
  {
    path: 'error',
    component: ErrorPageComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
]

import {Injectable} from '@angular/core';
import {ScopedStore} from "@lib/helpers/stores/scoped-store";
import {ProductStoreState} from "@facility/stores/products/product.models";
import {FacilityScope} from "@lib/scopes/facility.scope";
import {ProductClient} from "@facility/stores/products/product.client";
import {Reducers} from "@lib/helpers/stores/reducers";
import {WhereItem} from "@juulsgaard/store-service";
import {SharedProductStore} from "../../../shared/product/shared-product.store";

@Injectable()
export class ProductStore extends ScopedStore<ProductStoreState> implements SharedProductStore {

  products$ = this.selectNotNull(x => x.products);

  constructor(scope: FacilityScope, private client: ProductClient) {
    super({}, scope);
  }

  loadProducts = this.command(this.client)
    .withAction(c => c.loadAllProducts)
    .isInitial()
    .withReducer(products => ({products}));

  loadProduct = this.command(this.client)
    .withAction(c => c.loadProduct)
    .cancelConcurrent(x => x)
    .targetList('products')
    .withReducer(Reducers.updateById());

  loadReviewHistory = this.command(this.client)
    .withAction(c => c.loadReviewHistory)
    .cancelConcurrent(x => x)
    .targetList('products')
    .targetItem(WhereItem.IdMatchesPayload())
    .withReducer(reviews => ({reviews}));


  createProduct = this.command(this.client)
    .withAction(c => c.createProduct)
    .withSuccessMessage('Created a new Product')
    .targetList('products')
    .withReducer(Reducers.addition());

  updateProduct = this.command(this.client)
    .withAction(c => c.updateProduct)
    .withSuccessMessage('Updated Product')
    .targetList('products')
    .withReducer(Reducers.updateById());

  archiveProduct = this.command(this.client)
    .withDeferredAction(c => c.archiveProduct)
    .withSuccessMessage('Archived Product')
    .targetList('products')
    .withReducer(Reducers.deleteById());

  updateComposition = this.command(this.client)
    .withAction(c => c.updateComposition)
    .withSuccessMessage('Updated Composition')
    .targetList('products')
    .withReducer(Reducers.updateById());

  updateInstructions = this.command(this.client)
    .withAction(c => c.updateInstructions)
    .withSuccessMessage('Updated Instructions')
    .targetList('products')
    .withReducer(Reducers.updateById());


  submitProduct = this.command(this.client)
    .withDeferredAction(c => c.submitProduct)
    .withSuccessMessage('Submitted product for review')
    .targetList('products')
    .withReducer(Reducers.deleteById());
}

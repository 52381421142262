import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  MaterialCreateModel, MaterialListModel, MaterialLoadModel, MaterialResponseModel, MaterialUpdateModel,
  MaterialVariantAliasTargetModel, MaterialVariantListModel, MaterialVariantLoadModel, MaterialVariantTargetModel
} from "@admin/stores/materials/material.models";
import {tap} from "rxjs";
import {AliasTargetModel} from "@lib/models/alias.models";

@Injectable({providedIn: 'root'})
export class MaterialClient {
  constructor(private httpClient: HttpClient) {
  }

  //<editor-fold desc="Material Load">

  loadAdminMaterials() {
    return this.httpClient.get<MaterialListModel[]>(`admin://materials/admin`);
  }

  loadTenantAdminMaterials() {
    return this.httpClient.get<MaterialListModel[]>(`admin://tenant/materials/admin`);
  }

  loadAdminMaterial(materialId: string) {
    return this.httpClient.get<MaterialLoadModel>(`admin://material/${materialId}/admin`)
      .pipe(tap(x => x.loaded = true));
  }

  loadAdminMaterialVariant({variantId}: MaterialVariantTargetModel) {
    return this.httpClient.get<MaterialVariantLoadModel>(`admin://material/variant/${variantId}/admin`)
      .pipe(tap(x => x.loaded = true));
  }

  //</editor-fold>

  //<editor-fold desc="Material">

  createMaterial(data: MaterialCreateModel) {
    return this.httpClient.post<MaterialListModel>(`admin://materials`, data);
  }

  updateMaterial({id, ...data}: MaterialUpdateModel) {
    return this.httpClient.put<MaterialResponseModel>(`admin://material/${id}`, data);
  }

  archiveMaterial(materialId: string) {
    return this.httpClient.delete<void>(`admin://material/${materialId}`);
  }

  deleteAlias({parentId, aliasId}: AliasTargetModel) {
    return this.httpClient.delete<void>(`admin://material/${parentId}/alias/${aliasId}`);
  }

  //</editor-fold>

  addVariant({materialId, variantId}: MaterialVariantTargetModel) {
    return this.httpClient.post<MaterialVariantListModel>(`admin://material/${materialId}/variant/${variantId}`, {});
  }

  deleteVariant({materialId, variantId}: MaterialVariantTargetModel) {
    return this.httpClient.delete<void>(`admin://material/${materialId}/variant/${variantId}`);
  }

  deleteVariantAlias({materialVariantId, aliasId}: MaterialVariantAliasTargetModel) {
    return this.httpClient.delete<void>(`admin://material/variant/${materialVariantId}/alias/${aliasId}`);
  }
}

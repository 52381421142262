import {inject, Injectable} from '@angular/core';
import {ScopedStore} from "@lib/helpers/stores/scoped-store";
import {FacilitiesStoreState} from "@tenant/stores/facilities/facility.models";
import {TenantScope} from "@lib/scopes/tenant.scope";
import {FacilityClient} from "@tenant/stores/facilities/facility.client";
import {TenantStore} from "@admin/stores/tenants/tenant.store";
import {Reducers} from "@lib/helpers/stores/reducers";
import {map} from "rxjs/operators";
import {arrToMap} from "@juulsgaard/ts-tools";
import {WhereItem} from "@juulsgaard/store-service";

@Injectable()
export class FacilityStore extends ScopedStore<FacilitiesStoreState> {

  facilities$ = this.selectNotNull(x => x.facilities);
  facilityLookup$ = this.selector(
    this.facilities$.pipe(map(list => arrToMap(list, x => x.id)))
  );

  archivedFacilities$ = this.selectNotNull(x => x.archivedFacilities);

  private tenantStore = inject(TenantStore, {optional: true});

  constructor(scope: TenantScope, private client: FacilityClient) {
    super({}, scope);
  }

  loadFacilities = this.command(this.client)
    .withAction(c => c.loadFacilities)
    .isInitial()
    .withReducer((facilities) => ({facilities}));

  loadArchivedFacilities = this.command(this.client)
    .withAction(c => c.loadArchivedFacilities)
    .isInitial()
    .withReducer((archivedFacilities) => ({archivedFacilities}));

  loadFacility = this.command(this.client)
    .withAction(c => c.loadFacilityForTenant)
    .cancelConcurrent(x => x)
    .targetList('facilities')
    .withReducer(Reducers.updateById());

  createFacility = this.command(this.client)
    .withAction(c => c.createFacility)
    .withAfterEffect(facility => this.tenantStore?._createFacility.emit(facility))
    .withSuccessMessage('Created Factory')
    .targetList('facilities')
    .withReducer(Reducers.addition());

  updateFacility = this.command(this.client)
    .withAction(c => c.updateFacility)
    .withAfterEffect(facility => this.tenantStore?._updateFacility.emit(facility))
    .withSuccessMessage('Updated Factory')
    .targetList('facilities')
    .withReducer(Reducers.updateById());

  deleteAlias = this.command(this.client)
    .withDeferredAction(c => c.deleteAlias)
    .withAfterEffect((_, data) => this.tenantStore?._deleteFacilityAlias.emit(data))
    .withSuccessMessage('Deleted Alias')
    .targetList('facilities')
    .targetItem(WhereItem.IdMatchesPayload(x => x.parentId))
    .targetList('aliases')
    .modify(x => x.aliasId)
    .withReducer(Reducers.deleteById());

  archiveFacility = this.command(this.client)
    .withDeferredAction(c => c.archiveFacility)
    .withAfterEffect((_, id) => this.tenantStore?._archiveFacility.emit(id))
    .withSuccessMessage('Archived Factory')
    .withReducer(Reducers.moveArchiveById('facilities', 'archivedFacilities'));

  restoreFacility = this.command(this.client)
    .withAction(c => c.restoreFacility)
    .withAfterEffect(facility => this.tenantStore?._restoreFacility.emit(facility))
    .withSuccessMessage('Restored Factory')
    .withReducer(Reducers.moveRestoreById('facilities', 'archivedFacilities'));
}

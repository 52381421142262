import {inject, Injectable} from '@angular/core';
import {ScopedStore} from "@lib/helpers/stores/scoped-store";
import {AdminDashboardStoreState} from "@admin/stores/dashboard/admin-dashboard.models";
import {AdminDashboardClient} from "@admin/stores/dashboard/admin-dashboard.client";
import {AdminScope} from "@lib/scopes/admin.scope";

@Injectable()
export class AdminDashboardStore extends ScopedStore<AdminDashboardStoreState> {

  products$ = this.selectNotNull(x => x.products?.facilityProducts);
  productTotal$ = this.selectNotNull(({products}) => {
    if (!products) return undefined;
    const active = Object.values(products.facilityProducts).reduce((acc, stats) => acc + stats.active, 0);
    const review = Object.values(products.facilityProducts).reduce((acc, stats) => acc + stats.review, 0);
    return {active, review};
  });

  constructor(private client: AdminDashboardClient) {
    super({}, inject(AdminScope));
  }

  loadProductStats = this.command(this.client)
    .withAction(c => c.loadAdminProductStats)
    .isInitial()
    .withReducer(products => ({products}));
}

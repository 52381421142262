import {Injectable} from '@angular/core';
import {ScopedStore} from "@lib/helpers/stores/scoped-store";
import {Reducers} from "@lib/helpers/stores/reducers";
import {FacilityUserClient} from "@facility/stores/users/facility-user.client";
import {FacilityUserStoreState} from "@facility/stores/users/facility-user.models";
import {FacilityScope} from "@lib/scopes/facility.scope";
import {WhereItem} from "@juulsgaard/store-service";

@Injectable()
export class FacilityUserStore extends ScopedStore<FacilityUserStoreState> {

  users$ = this.selectNotNull(x => x.users);

  constructor(scope: FacilityScope, private client: FacilityUserClient) {
    super({}, scope);
  }

  loadUsers = this.command(this.client)
    .withAction(c => c.loadUsers)
    .isInitial()
    .withReducer(users => ({users}));

  loadUser = this.command(this.client)
    .withAction(c => c.loadUser)
    .cancelConcurrent(x => x)
    .targetList('users')
    .withReducer(Reducers.updateById());

  createUser = this.command(this.client)
    .withAction(c => c.addUser)
    .withSuccessMessage('Created new Worker Account')
    .targetList('users')
    .withReducer(Reducers.addition());

  updateUser = this.command(this.client)
    .withAction(c => c.updateUser)
    .withSuccessMessage('Updated Worker Account')
    .targetList('users')
    .withReducer(Reducers.updateById());

  requestPassword = this.command(this.client)
    .withDeferredAction(c => c.requestPassword)
    .withSuccessMessage('Requested new password')
    .targetList('users')
    .targetItem(WhereItem.IdMatchesPayload())
    .withReducer(() => ({requiresNewPassword: true}));

  resetLoginAttempts = this.command(this.client)
    .withDeferredAction(c => c.resetLoginAttempts)
    .withSuccessMessage('Reset failed login attempts')
    .targetList('users')
    .targetItem(WhereItem.IdMatchesPayload())
    .withReducer(() => ({failedLoginAttempts: 0, lockedOut: false}));

  updatePassword = this.command(this.client)
    .withDeferredAction(c => c.updatePassword)
    .withSuccessMessage('Updated password')
    .targetList('users')
    .targetItem(WhereItem.IdMatchesPayload(x => x.userId))
    .withReducer(() => ({lastPasswordReset: new Date().toString(), hasPassword: true}));

  removeUser = this.command(this.client)
    .withDeferredAction(c => c.removeUser)
    .withSuccessMessage('Worker Account was Removed')
    .targetList('users')
    .withReducer(Reducers.deleteById());
}

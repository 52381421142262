import {Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {AsyncPipe, NgIf} from "@angular/common";

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
  imports: [
    NgIf,
    AsyncPipe
  ],
  standalone: true
})
export class ErrorPageComponent {

  reason$: Observable<undefined|string>;

  constructor(private route: ActivatedRoute) {
    this.reason$ = route.queryParams.pipe(
      map(x => x['reason'])
    );
  }

}

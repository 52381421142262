import {Injectable} from "@angular/core";
import {registerLocaleData} from "@angular/common";
import dayjs from "dayjs";

@Injectable({providedIn: 'root'})
export class LocaleService {

  private static defaultLocale = 'en';
  private static supportedLocales = ['de', 'en-GB', 'en', 'es', 'fr', 'da', 'it', 'nl', 'no', 'se', 'ru', 'ja'];

  constructor() {
    const language = navigator.languages?.[0] ?? navigator.language;

    const locale = LocaleService.getLocale(language.toLowerCase());
    this.locale = locale.toLowerCase();

    const group = locales[locale.toLowerCase()] ?? locales.en;

    group.dayjs().then(l => dayjs.locale(l));

    group.ng()
      .then(module => registerLocaleData(module.default))
      .catch(() => {
        console.error(`Failed to load locale: ${locale}`);
        this._locale = LocaleService.defaultLocale;
      });
  }

  private _locale = LocaleService.defaultLocale;
  set locale(locale: string) {
    this._locale = locale;

  }
  get locale() {
    return this._locale
  }

  private static getLocale(language: string): string {
    if (!language) return LocaleService.defaultLocale;

    const normalised = language.toLowerCase();
    for (let locale of LocaleService.supportedLocales) {
      if (normalised.startsWith(locale.toLowerCase())) return locale;
    }

    return LocaleService.defaultLocale;
  }
}

interface LocaleGroup {
  ng: () => Promise<any>;
  dayjs: () => Promise<ILocale>;
}
type Locales = Record<string, LocaleGroup> & {en: LocaleGroup};

const locales: Locales = {
  en: {
    ng: () => import('@angular/common/locales/en'),
    dayjs: () => import('dayjs/locale/en')
  },
  'en-gb': {
    ng: () => import('@angular/common/locales/en-GB'),
    dayjs: () => import('dayjs/locale/en-gb')
  },
  da: {
    ng: () => import('@angular/common/locales/da'),
    dayjs: () => import('dayjs/locale/da')
  },
  se: {
    ng: () => import('@angular/common/locales/se'),
    dayjs: () => import('dayjs/locale/se')
  },
  ru: {
    ng: () => import('@angular/common/locales/ru'),
    dayjs: () => import('dayjs/locale/ru')
  },
  es: {
    ng: () => import('@angular/common/locales/es'),
    dayjs: () => import('dayjs/locale/es')
  },
  fr: {
    ng: () => import('@angular/common/locales/fr'),
    dayjs: () => import('dayjs/locale/fr')
  },
  de: {
    ng: () => import('@angular/common/locales/fr'),
    dayjs: () => import('dayjs/locale/fr')
  }
};

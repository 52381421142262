import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AggregateProductStatsModel} from "@admin/stores/dashboard/admin-dashboard.models";
import {ReviewStatsModel} from "@tenant/stores/dashboard/tenant-dashboard.models";

@Injectable({providedIn: 'root'})
export class TenantDashboardClient {
  constructor(private httpClient: HttpClient) {
  }

  loadTenantProductStats() {
    return this.httpClient.get<AggregateProductStatsModel>(`tenant://dashboard/tenant/products`);
  }

  loadReviewStats() {
    return this.httpClient.get<ReviewStatsModel>(`tenant://dashboard/tenant/reviews`);
  }
}

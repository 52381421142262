import {inject, Injectable} from '@angular/core';
import {IconSetClient} from "@admin/stores/icon-sets/icon-set.client";
import {ScopedStore} from "@lib/helpers/stores/scoped-store";
import {IconSetState} from "@admin/stores/icon-sets/icon-set.models";
import {AdminScope} from "@lib/scopes/admin.scope";
import {Reducers} from "@lib/helpers/stores/reducers";
import {WhereItem} from "@juulsgaard/store-service";
import {InstructionStore} from "@admin/stores/instructions/instruction.store";
import {map} from "rxjs/operators";
import {arrToMap} from "@juulsgaard/ts-tools";

@Injectable()
export class IconSetStore extends ScopedStore<IconSetState> {

  iconSets$ = this.selectNotNull(x => x.iconSets);
  iconSetLookup$ = this.selector(
    this.iconSets$.pipe(map(list => arrToMap(list, x => x.id)))
  )

  constructor(private client: IconSetClient, private instructionStore: InstructionStore) {
    super({}, inject(AdminScope));
  }

  loadIconSets = this.command(this.client)
    .withAction(c => c.loadIconSets)
    .isInitial()
    .withReducer(iconSets => ({iconSets}));

  loadIconSet = this.command(this.client)
    .withAction(c => c.loadIconSet)
    .cancelConcurrent(x => x)
    .targetList('iconSets')
    .withReducer(Reducers.updateById());

  createIconSet = this.command(this.client)
    .withAction(c => c.createIconSet)
    .withSuccessMessage('Created Icon Set')
    .targetList('iconSets')
    .withReducer(Reducers.addition());

  updateIconSet = this.command(this.client)
    .withAction(c => c.updateIconSet)
    .withSuccessMessage('Updated Icon Set')
    .targetList('iconSets')
    .withReducer(Reducers.updateById());

  archiveIconSet = this.command(this.client)
    .withDeferredAction(c => c.archiveIconSet)
    .withSuccessMessage('Archived Icon Set')
    .targetList('iconSets')
    .withReducer(Reducers.archiveById());

  setIconAlt = this.command(this.client)
    .withAction(c => c.setIconAlt)
    .withSuccessMessage('Uploaded Icon')
    .withAfterEffect((alt, {iconId, slotId}) => this.instructionStore._setIconAlt.emit({slotId, iconId, alt}))
    .targetList('iconSets')
    .targetItem(WhereItem.IdMatchesPayload(x => x.iconSetId))
    .targetList('icons')
    .withReducer(Reducers.setElement(x => x.iconId));
}

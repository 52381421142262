import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  ProductReviewResponseModel, ProductReviewUpdateModel, ReviewListModel, ReviewLoadModel
} from "@tenant/stores/reviews/review.models";
import {tap} from "rxjs";
import {
  ProductCompositionResponseModel, ProductCompositionUpdateModel, ProductInstructionsResponseModel,
  ProductInstructionsUpdateModel, ProductResponseModel, ProductUpdateModel
} from "@facility/stores/products/product.models";

@Injectable({providedIn: 'root'})
export class ReviewClient {
  constructor(private httpClient: HttpClient) {
  }

  //<editor-fold desc="Review">

  updateReview({productId, ...data}: ProductReviewUpdateModel) {
    return this.httpClient.put<ProductReviewResponseModel>(`tenant://tenant/review/${productId}`, data);
  }

  approveReview(productId: string) {
    return this.httpClient.post<void>(`tenant://tenant/review/${productId}/approve`, {});
  }

  denyReview(productId: string) {
    return this.httpClient.post<void>(`tenant://tenant/review/${productId}/deny`, {});
  }

  unapproveReview(productId: string) {
    return this.httpClient.post<ReviewListModel>(`tenant://tenant/review/${productId}/unapprove`, {});
  }

  //</editor-fold>

  //<editor-fold desc="Review Load">

  loadReviews() {
    return this.httpClient.get<ReviewListModel[]>(`tenant://tenant/reviews`);
  }

  loadReview(productId: string) {
    return this.httpClient.get<ReviewLoadModel>(`tenant://tenant/review/${productId}`)
      .pipe(tap(x => x.loaded = true));
  }

  //</editor-fold>

  updateComposition({id, ...data}: ProductCompositionUpdateModel) {
    return this.httpClient.put<ProductCompositionResponseModel>(`tenant://product/${id}/composition`, data);
  }

  updateInstructions({id, ...data}: ProductInstructionsUpdateModel) {
    return this.httpClient.put<ProductInstructionsResponseModel>(`tenant://product/${id}/instructions`, data);
  }

  updateProduct({id, ...data}: ProductUpdateModel) {
    return this.httpClient.put<ProductResponseModel>(`tenant://product/${id}`, data);
  }

  printProduct(productId: string) {
    return this.httpClient.post<void>(`tenant://tenant/product/${productId}/print`, {});
  }
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  IconSetCreateModel, IconSetListModel, IconSetLoadModel, IconSetResponseModel, IconSetUpdateModel
} from "@admin/stores/icon-sets/icon-set.models";
import {tap} from "rxjs";
import {AdminInstructionIconAlt, InstructionIconAltUploadModel} from "@admin/stores/instructions/instruction.models";

@Injectable({providedIn: 'root'})
export class IconSetClient {
  constructor(private httpClient: HttpClient) {
  }

  //<editor-fold desc="Icon Set">

  createIconSet(data: IconSetCreateModel) {
    return this.httpClient.post<IconSetListModel>(`admin://icon-sets`, data);
  }

  updateIconSet({id, ...data}: IconSetUpdateModel) {
    return this.httpClient.put<IconSetResponseModel>(`admin://icon-set/${id}`, data);
  }

  archiveIconSet(iconSetId: string) {
    return this.httpClient.delete<void>(`admin://icon-set/${iconSetId}`);
  }

  setIconAlt({iconId, iconSetId, image}: InstructionIconAltUploadModel) {
    const payload = new FormData();
    payload.set('file', image);
    return this.httpClient.post<AdminInstructionIconAlt>(`admin://instruction/icon/${iconId}/alt/${iconSetId}`, payload);
  }

  //</editor-fold>

  //<editor-fold desc="Icon Set Load">

  loadIconSets() {
    return this.httpClient.get<IconSetListModel[]>(`admin://icon-sets/admin`);
  }

  loadIconSet(iconSetId: string) {
    return this.httpClient.get<IconSetLoadModel>(`admin://icon-set/${iconSetId}/admin`)
      .pipe(tap(x => x.loaded = true));
  }

  //</editor-fold>
}

import {inject, Injectable} from '@angular/core';
import {ScopedStore} from "@lib/helpers/stores/scoped-store";
import {PrintGrouping, PrintState} from "@admin/stores/print/print.models";
import {AdminScope} from "@lib/scopes/admin.scope";
import {PrintClient} from "@admin/stores/print/print.client";
import {Reducers} from "@lib/helpers/stores/reducers";
import {arrToSet} from "@juulsgaard/ts-tools";

@Injectable()
export class PrintStore extends ScopedStore<PrintState> {

  requests$ = this.selectNotNull(x => x.requests);
  grouping$ = this.selectNotNull(x => x.grouping);
  missingTranslations$ = this.selectNotNull(x => x.missingTranslations);

  constructor(private client: PrintClient) {
    super({}, inject(AdminScope));
  }

  loadPrintRequests = this.command(this.client)
    .withAction(c => c.loadPrintRequests)
    .isInitial()
    .withReducer(x => x);

  markAsPrinted = this.command(this.client)
    .withDeferredAction(c => c.markAsPrinted)
    .targetList('requests')
    .withReducer(Reducers.deleteById());

  markAsPrintedTenant = this.command(this.client)
    .withAction(c => c.markAsPrintedTenant)
    .withSuccessMessage('Marked group as printed')
    .targetList('requests')
    .modify(x => arrToSet(x))
    .withReducer((affected, list) => list.filter(x => !affected.has(x.id)));

  markAsPrintedFacility = this.command(this.client)
    .withAction(c => c.markAsPrintedFacility)
    .withSuccessMessage('Marked group as printed')
    .targetList('requests')
    .modify(x => arrToSet(x))
    .withReducer((affected, list) => list.filter(x => !affected.has(x.id)));

  cancelPrint = this.command(this.client)
    .withDeferredAction(c => c.cancelPrint)
    .withSuccessMessage('Print request was cancelled')
    .targetList('requests')
    .withReducer(Reducers.deleteById());

  updateGrouping = this.command()
    .withPayload<PrintGrouping>()
    .withReducer(grouping => ({grouping}));
}

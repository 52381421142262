import {inject, Injectable} from '@angular/core';
import {ScopedStore} from "@lib/helpers/stores/scoped-store";
import {VariantState} from "@admin/stores/variants/variant.models";
import {VariantClient} from "@admin/stores/variants/variant.client";
import {AdminScope} from "@lib/scopes/admin.scope";
import {Reducers} from "@lib/helpers/stores/reducers";
import {WhereItem} from "@juulsgaard/store-service";
import {arrToMap} from "@juulsgaard/ts-tools";
import {map} from "rxjs/operators";

@Injectable()
export class VariantStore extends ScopedStore<VariantState> {

  variants$ = this.selectNotNull(x => x.variants);
  variantLookup$ = this.selector(
    this.variants$.pipe(map(v => arrToMap(v, x => x.id)))
  );

  constructor(private client: VariantClient) {
    super({}, inject(AdminScope));
  }

  loadVariants = this.command(this.client)
    .withAction(c => c.loadAdminVariants)
    .isInitial()
    .withReducer(variants => ({variants}));

  loadVariant = this.command(this.client)
    .withAction(c => c.loadAdminVariant)
    .cancelConcurrent(x => x)
    .targetList('variants')
    .withReducer(Reducers.updateById());

  createVariant = this.command(this.client)
    .withAction(c => c.createVariant)
    .withSuccessMessage('Created Variant')
    .targetList('variants')
    .withReducer(Reducers.addition());

  updateVariant = this.command(this.client)
    .withAction(c => c.updateVariant)
    .withSuccessMessage('Updated Variant')
    .targetList('variants')
    .withReducer(Reducers.updateById());

  archiveVariant = this.command(this.client)
    .withDeferredAction(c => c.archiveVariant)
    .withSuccessMessage('Archived Variant')
    .targetList('variants')
    .withReducer(Reducers.deleteById());

  deleteAlias = this.command(this.client)
    .withDeferredAction(c => c.deleteAlias)
    .withSuccessMessage('Deleted Alias')
    .targetList('variants')
    .targetItem(WhereItem.IdMatchesPayload(x => x.parentId))
    .targetList('aliases')
    .modify(x => x.aliasId)
    .withReducer(Reducers.deleteById());
}

import {Injectable} from '@angular/core';
import {ScopedStore} from "@lib/helpers/stores/scoped-store";
import {AdminTagData, TagStoreState} from "@tenant/stores/tags/tag.models";
import {TagClient} from "@tenant/stores/tags/tag.client";
import {Reducers} from "@lib/helpers/stores/reducers";
import {arrToMap, mapArr} from "@juulsgaard/ts-tools";
import {Parsers} from "@lib/helpers/stores/parsers";
import {TenantScope} from "@lib/scopes/tenant.scope";

@Injectable()
export class TagStore extends ScopedStore<TagStoreState> {

  tags$ = this.selectNotNull(x => x.tags);
  scopes$ = this.selectNotNull(x => x.scopes);

  tagData$ = this.selectNotNull(({tags, scopes}) => {
    if (!tags || !scopes) return undefined;
    const lookup = arrToMap(scopes, x => x.id);
    return mapArr(tags, tag => {
      const scope = lookup.get(tag.scopeId);
      if (!scope) return undefined;
      return {
        ...tag,
        scopeId: scope.id,
        scopeName: scope.name,
        color: scope.color
      } satisfies AdminTagData;
    })
  });

  constructor(scope: TenantScope, private client: TagClient) {
    super({}, scope);
  }

  loadTags = this.command(this.client)
    .withAction(c => c.loadAdminTags)
    .withParser(Parsers.dates(true))
    .isInitial()
    .withReducer(x => x);

  //<editor-fold desc="Scopes">
  createScope = this.command(this.client)
    .withAction(c => c.createScope)
    .withSuccessMessage('Created a new Tag Scope')
    .targetList('scopes')
    .withReducer(Reducers.addition());

  updateScope = this.command(this.client)
    .withAction(c => c.updateScope)
    .withSuccessMessage('Updated the Scope')
    .targetList('scopes')
    .withReducer(Reducers.updateById());

  deleteScope = this.command(this.client)
    .withDeferredAction(c => c.deleteScope)
    .withSuccessMessage('Deleted the Scope')
    .targetList('scopes')
    .withReducer(Reducers.deleteById());
  //</editor-fold>

  //<editor-fold desc="Tags">
  createTag = this.command(this.client)
    .withAction(c => c.createTag)
    .withParser(Parsers.dates(true))
    .withSuccessMessage('Created a new Tag')
    .targetList('tags')
    .withReducer(Reducers.addition());

  updateTag = this.command(this.client)
    .withAction(c => c.updateTag)
    .withParser(Parsers.dates(true))
    .withSuccessMessage('Updated the tag')
    .targetList('tags')
    .withReducer(Reducers.updateById());

  deleteTag = this.command(this.client)
    .withDeferredAction(c => c.deleteTag)
    .withSuccessMessage('Deleted the Tag')
    .targetList('tags')
    .withReducer(Reducers.deleteById());
  //</editor-fold>
}

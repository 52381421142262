import {Injectable} from '@angular/core';
import {IScope} from "./scope.interface";
import {filter, Observable, pairwise} from "rxjs";
import {AuthService} from "../services/auth.service";
import {map} from "rxjs/operators";
import {AuthClient} from "../clients/auth.client";

@Injectable()
export class UserScope implements IScope {

  reset$: Observable<void>;
  get refreshToken() {return this.authService.refreshToken}

  userId$: Observable<string|undefined>;

  isAdmin$: Observable<boolean>;
  isSuperAdmin$: Observable<boolean>;

  get isAdmin() {return this.authService.isAdmin}
  get isSuperAdmin() {return this.authService.isSuperAdmin}

  constructor(private authService: AuthService, private client: AuthClient) {
    // Reset when the userId is reset or changed from one valid value to another
    // Reset won't be emitted when the userId changes from undefined to a value
    this.reset$ = authService.userId$.pipe(
      pairwise(),
      filter(([oldVal, newVal]) => !!oldVal && oldVal !== newVal),
      map(() => undefined)
    );

    this.userId$ = this.authService.userId$;
    this.isAdmin$ = this.authService.isAdmin$;
    this.isSuperAdmin$ = this.authService.isSuperAdmin$;
  }

}

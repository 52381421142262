import {isObject, isString} from "@juulsgaard/ts-tools";
import dayjs, {Dayjs} from "dayjs";

export module Parsers {
  export function dates(utc = true) {
    return <TModel>(model: TModel) =>  parseObject(model, x => parseDateStr(x, utc));
  }
}

export function parseObject<T>(obj: T, parse: (val: any) => any): T {

  if (obj == null) return obj;

  if (Array.isArray(obj)) {
    const ret: any[] = [];

    obj.forEach(val => {
      ret.push(parseObject(val, parse));
    });

    return ret as typeof obj;
  }

  if (isObject(obj)) {

    const ret = {} as Record<string, any>;
    for (let [key, val] of Object.entries(obj)) {
      ret[key] = parseObject(val, parse);
    }

    return ret as typeof obj;
  }

  const temp = parse(obj);
  return temp === null ? obj : temp;
}

export function parseDateStr<TIn>(str: TIn, fromUtc: boolean): Date|TIn {

  if (str instanceof Date) {
    return str;
  }

  if (!isString(str)) return str;

  let date: Dayjs|undefined;

  if (/^\d{2}:\d{2}:\d{2}$/.test(str)) {
    date = fromUtc
      ? dayjs.utc(`1970-01-01 ${str}`, 'YYYY-MM-DD HH:mm:ss', true)
      : dayjs(`1970-01-01 ${str}`, 'YYYY-MM-DD HH:mm:ss', true);
  } else if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?Z?$/.test(str)) {
    date = fromUtc
      ? dayjs.utc(str)
      : dayjs(str);
  } else {
    return str;
  }

  if (!date.isValid()) return str;

  return date.toDate();
}

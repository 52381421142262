import {HttpStatusCode} from "@angular/common/http";
import {ApiException} from "./api.exception";


export class AuthException extends ApiException {

    constructor(message: string, public loggedOut = false, correlationId?: string) {
        super(message, HttpStatusCode.Unauthorized, correlationId);
    }
}

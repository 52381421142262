import {inject, Injectable} from '@angular/core';
import {ScopedStore} from "@lib/helpers/stores/scoped-store";
import {FacilityDashboardStoreState} from "@facility/stores/dashboard/facility-dashboard.models";
import {FacilityDashboardClient} from "@facility/stores/dashboard/facility-dashboard.client";
import {FacilityScope} from "@lib/scopes/facility.scope";

@Injectable()
export class FacilityDashboardStore extends ScopedStore<FacilityDashboardStoreState> {

  products$ = this.selectNotNull(x => x.products);

  constructor(private client: FacilityDashboardClient) {
    super({}, inject(FacilityScope));
  }

  loadProductStats = this.command(this.client)
    .withAction(c => c.loadFacilityProductStats)
    .isInitial()
    .withReducer(products => ({products}));
}

import {inject, Injectable} from '@angular/core';
import {ScopedStore} from "@lib/helpers/stores/scoped-store";
import {ClaimState} from "@tenant/stores/claims/claim.models";
import {TenantScope} from "@lib/scopes/tenant.scope";
import {ClaimClient} from "@tenant/stores/claims/claim.client";
import {Reducers} from "@lib/helpers/stores/reducers";

@Injectable()
export class ClaimStore extends ScopedStore<ClaimState> {

  claims$ = this.selectNotNull(x => x.claims);

  constructor(private client: ClaimClient) {
    super({}, inject(TenantScope));
  }

  loadClaims = this.command(this.client)
    .withAction(c => c.loadClaimsAdmin)
    .isInitial()
    .withReducer(claims => ({claims}));

  createClaim = this.command(this.client)
    .withAction(c => c.createClaim)
    .withSuccessMessage('Created a Claim')
    .targetList('claims')
    .withReducer(Reducers.addition());

  updateClaim = this.command(this.client)
    .withAction(c => c.updateClaim)
    .withSuccessMessage('Updated Claim')
    .targetList('claims')
    .withReducer(Reducers.updateById());

  archiveClaim = this.command(this.client)
    .withDeferredAction(c => c.archiveClaim)
    .withSuccessMessage('Archived Claim')
    .targetList('claims')
    .withReducer(Reducers.archiveById());
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  AdminTenantSettings, BlacklistUpdateModel, MaterialBlacklistResponseModel, MaterialBlacklistUpdateModel,
  TenantBlacklistLoadModel, TenantLanguageLoadModel, TenantLanguageUpdateModel, TenantSettingsUpdateModel
} from "@tenant/stores/settings/tenant-settings.models";

@Injectable({providedIn: 'root'})
export class TenantSettingsClient {
  constructor(private httpClient: HttpClient) {
  }

  loadBlacklists() {
    return this.httpClient.get<TenantBlacklistLoadModel>(`tenant://tenant/blacklists`);
  }

  updateIconBlacklist(data: BlacklistUpdateModel) {
    return this.httpClient.put<string[]>(`tenant://tenant/blacklist/icons`, data);
  }

  updateTextBlacklist(data: BlacklistUpdateModel) {
    return this.httpClient.put<string[]>(`tenant://tenant/blacklist/text`, data);
  }

  updateMaterialBlacklist(data: MaterialBlacklistUpdateModel) {
    return this.httpClient.put<MaterialBlacklistResponseModel>(`tenant://tenant/blacklist/materials`, data);
  }

  loadLanguageSettings() {
    return this.httpClient.get<TenantLanguageLoadModel>(`tenant://tenant/settings/languages`);
  }

  updateLanguageSettings(data: TenantLanguageUpdateModel) {
    return this.httpClient.put<TenantLanguageLoadModel>(`tenant://tenant/settings/languages`, data);
  }

  removeMaterialFromBlacklist(materialId: string) {
    return this.httpClient.delete<string[]>(`tenant://tenant/blacklist/material/${materialId}`);
  }

  removeVariantFromBlacklist(materialVariantId: string) {
    return this.httpClient.delete<void>(`tenant://tenant/blacklist/materials/variant/${materialVariantId}`);
  }

  loadSettings() {
    return this.httpClient.get<AdminTenantSettings>(`tenant://tenant/settings`);
  }

  updateSettings(data: TenantSettingsUpdateModel) {
    return this.httpClient.put<AdminTenantSettings>(`tenant://tenant/settings`, data);
  }
}

import {Injectable} from '@angular/core';
import {IStoreConfigService} from "@juulsgaard/store-service";
import {SnackbarService} from "@juulsgaard/ngx-material";
import {ApiException} from "@lib/exceptions/api.exception";
import {environment} from "../../env/environment";

@Injectable({providedIn: 'root'})
export class StoreConfigService implements IStoreConfigService {

  constructor(private snacks: SnackbarService) {
  }

  logActionRetry(command: string, attempt: number, nextDelay: number): void {
    console.warn(`Failed to execute "${command}" [Attempt #${attempt}]. Retrying in ${nextDelay}ms`)
  }

  displayError(message: string|undefined, error: Error): void {

    let data: any;

    if (error instanceof ApiException) {
      message ??= error.message;
      data = {correlationId: error.correlationId, error: error.message}
    } else {
      message ??= error.message;
    }

    this.snacks.error(message ?? '', undefined, data);
  }

  displaySuccess(message: string): void {
    this.snacks.success(message);
  }

  readonly isProduction = environment.production;
  readonly disableCache = false;

  errorIsCritical(error: any): boolean {

    // Filter out auth exceptions
    if (error instanceof ApiException) {
      if (error.statusCode === 401) return true;
    }

    return false;
  }
}

import {applyQueryParam, isBool, isString} from "@juulsgaard/ts-tools";

export function downloadPost(url: string, values: Record<string, any>, token: string) {

  const form = document.createElement('form');
  form.action = applyQueryParam(url, 'bodyAuth', 'true');
  form.method = 'post';
  form.style.display = 'none';

  document.body.appendChild(form);

  const tokenInput = document.createElement('input');
  tokenInput.type = 'text';
  tokenInput.name = 'token';
  tokenInput.value = token;

  form.appendChild(tokenInput);

  for (let key in values) {
    const valueInput = document.createElement('input');
    const value = values[key]!;

    valueInput.type = isBool(value) ? 'checkbox' : isString(value) ? 'text' : 'number';
    valueInput.name = key;

    if (isBool(value)) {
      valueInput.value = 'true';
      if (value) valueInput.setAttribute('checked', 'checked')
    } else {
      valueInput.value = values[key]!.toString();
    }

    form.appendChild(valueInput);
  }

  form.submit();

  setTimeout(() => {
    form.remove();
  }, 500);
}

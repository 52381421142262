import {inject, Injectable} from '@angular/core';
import {ScopedStore} from "@lib/helpers/stores/scoped-store";
import {TenantSettingsState} from "@tenant/stores/settings/tenant-settings.models";
import {TenantScope} from "@lib/scopes/tenant.scope";
import {map} from "rxjs/operators";
import {TenantSettingsClient} from "@tenant/stores/settings/tenant-settings.client";
import {removeListItem} from "@lib/helpers/stores/reducer-utils";
import {Reducers} from "@lib/helpers/stores/reducers";

@Injectable()
export class TenantSettingsStore extends ScopedStore<TenantSettingsState> {

  languages$ = this.selectNotNull(x => x.languages);
  blacklists$ = this.selectNotNull(x => x.blacklists);

  iconBlacklist$ = this.selector(this.blacklists$.pipe(map(x => x.iconBlacklist)));
  textBlacklist$ = this.selector(this.blacklists$.pipe(map(x => x.textBlacklist)));
  materialBlacklist$ = this.selector(this.blacklists$.pipe(map(x => ({materials: x.materialBlacklist, variants: x.variantBlacklist}))));

  settings$ = this.selectNotNull(x => x.settings);

  constructor(private client: TenantSettingsClient) {
    super({}, inject(TenantScope));
  }

  loadBlacklists = this.command(this.client)
    .withAction(c => c.loadBlacklists)
    .isInitial()
    .withReducer(blacklists => ({blacklists}));

  loadLanguages = this.command(this.client)
    .withAction(c => c.loadLanguageSettings)
    .isInitial()
    .withReducer(languages => ({languages}));


  updateLanguages = this.command(this.client)
    .withAction(c => c.updateLanguageSettings)
    .withSuccessMessage('Updated Languages Settings')
    .withReducer(languages => ({languages}));


  updateIconBlacklist = this.command(this.client)
    .withAction(c => c.updateIconBlacklist)
    .withSuccessMessage('Updated Icon Blacklist')
    .targetProp('blacklists')
    .withReducer(iconBlacklist => ({iconBlacklist}));

  updateTextBlacklist = this.command(this.client)
    .withAction(c => c.updateTextBlacklist)
    .withSuccessMessage('Updated Care Text Blacklist')
    .targetProp('blacklists')
    .withReducer(textBlacklist => ({textBlacklist}));

  updateMaterialBlacklist = this.command(this.client)
    .withAction(c => c.updateMaterialBlacklist)
    .withSuccessMessage('Updated Material Blacklist')
    .targetProp('blacklists')
    .withReducer(({materialIds, variantIds}) => ({materialBlacklist: materialIds, variantBlacklist: variantIds}));

  removeMaterialFromBlacklist = this.command(this.client)
    .withAction(c => c.removeMaterialFromBlacklist)
    .withSuccessMessage('Removed Material from blacklist')
    .targetProp('blacklists')
    .withReducer((removed, {materialBlacklist, variantBlacklist}, materialId) => ({
      materialBlacklist: removeListItem(materialBlacklist, x => x === materialId),
      variantBlacklist: variantBlacklist.filter(x => !removed.includes(x))
    }));

  removeVariantFromBlacklist = this.command(this.client)
    .withDeferredAction(c => c.removeVariantFromBlacklist)
    .withSuccessMessage('Removed Variant from blacklist')
    .targetProp('blacklists')
    .targetProp('variantBlacklist')
    .withReducer(Reducers.deleteElement(x => x));

  loadSettings = this.command(this.client)
    .withAction(c => c.loadSettings)
    .isInitial()
    .withReducer(settings => ({settings}));

  updateSettings = this.command(this.client)
    .withAction(c => c.updateSettings)
    .withSuccessMessage('Updated Settings')
    .withReducer(settings => ({settings}));
}
